<template>

	<div id="app">
	</div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
	name: "Logout",
	mounted: function() {
		localStorage.removeItem('localCartData')
		this.$store.state.cartCount = 0;
		localStorage.setItem("token","");
		this.setToken('');
		this.$router.replace("/");
	},
	methods:{
		...mapMutations(['setToken'])
	}
};
</script>
