import HomePage from "../views/HomePage.vue";
import TrackOrderPage from "../views/TrackOrderPage.vue";
import AllProducts from "../views/AllProducts.vue";
import BillingPage from "../views/BillingPage.vue";
import ProductDetails from "../views/ProductDetails.vue";
import SearchResults from "../views/SearchResults.vue";
import SearchResultNew from "../views/SearchResultNew.vue";
import AboutPage from "../views/AboutPage.vue";
import VendorPage from "../views/VendorPage.vue";
import HelpPage from "../views/HelpPage.vue";
import StaticPage from "../views/StaticPage.vue";
import CheckoutPage from "../views/CheckoutPage.vue";
import NotificationPage from "../views/NotificationPage.vue";
import ActiveateUserRegister from "../views/ActiveateUserRegister.vue";

import MyCartProducts from "../views/MyCartProducts.vue";
import ContactPage from "../views/ContactPage.vue";
import OutletsPage from "../views/OutletsPage.vue";
import Logout from "../components/Logout.vue";
import PageSuccess from "../views/PageSuccess.vue";
import BatoPage from "../views/BatoPage.vue";
import PageError from "../views/PageError.vue";

export default [
  { path: "/", name: "homepage", component: HomePage },
  {
    path: "/track-order-page/:id",
    name: "trackOrderPage",
    component: TrackOrderPage,
  },
  { path: "/all-products/:id", name: "allproducts", component: AllProducts },
  { path: "/my-bill/:id", name: "billingpage", component: BillingPage },
  { path: "/checkout", name: "checkoutPage", component: CheckoutPage },
  {
    path: "/product-details/:id",
    name: "productdetails",
    component: ProductDetails,
  },
  { path: "/search-results", name: "search", component: SearchResults },
  { path: "/search-now", name: "searchResultNew", component: SearchResultNew },
  { path: "/about", name: "about", component: AboutPage },
  { path: "/vendor/:slug", name: "vendor", component: VendorPage },
  { path: "/help", name: "help", component: HelpPage },
  { path: "/page/:slug", name: "staticPage", component: StaticPage },
  { path: "/my-cart", name: "mycartproducts", component: MyCartProducts },
  { path: "/contact", name: "contactpage", component: ContactPage },
  {
    path: "/notifications",
    name: "notificationPage",
    component: NotificationPage,
  },
  {
    path: "/activate",
    name: "activeateUserRegister",
    component: ActiveateUserRegister,
  },
  { path: "/outlets", name: "outletspage", component: OutletsPage },
  { path: "/logout", name: "logout", component: Logout },
  { path: "/success", name: "success", component: PageSuccess },
  { path: "/batoio", name: "BatoPage", component: BatoPage },
  { path: "/error", name: "Error", component: PageError },
];
