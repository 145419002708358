<template>
  <div class="gg-container">
    <div class="all-products">
      <!-- <AllProductsCategories/> -->
      <!-- <AllProductsCards/> -->
      <div class="all-product-categories">
        <h1>Product Categories</h1>
        
        <div class="all-products-links">
          <router-link :to="{name: 'allproducts', params: {id: 'All'}}">All Products</router-link>
          <router-link
            class="categories-link"
            v-for="(category_obj_1, index) in categories_list"
            :key="index"
            v-if="checkValidCategory(category_obj_1)"
            @click="CategoryProduct(category_obj_1.name)"
            :to="{name: 'allproducts', params: {id: category_obj_1.name}}"
          >{{category_obj_1.name}}</router-link>
        </div>
      </div>
      <div class="allproduct-exclusive">
        <div class="product-heading">
          <h1 v-if="$route.params.id != 'All'">{{$route.params.id}}</h1>
          <h1 v-else>All Products</h1>
        </div>
        <div class="products-wrapper">
          <div
            class="product-card"
            v-for="(product_obj,index) in product_list"
            :key="index"
            v-if="product_obj.product_status == 'Available' && product_obj.total_quantity > 0"
          >
            <div class="product-image" id="product-details">
              <router-link
                :to="{name: 'productdetails', params: {id: product_obj.id}}"
                class="product-description"
              >
              <img :src="product_obj.image" alt="image" />
              </router-link>
              <div class="preview-product-icons">
                <button class="preview-icon" @click="isPreviewModal(product_obj.id)" title="Preview">
                  <i class="far fa-eye"></i>
                </button>
                <button class="add-to-cart-icon" @click="addToCartModal(product_obj)" title="Add Cart">
                  <i class="fas fa-cart-plus"></i>
                </button>
              </div>
            </div>
            <div class="description-card">
              <router-link
                :to="{name: 'productdetails', params: {id: product_obj.id}}"
                class="product-description"
              >
                <h1>{{product_obj.name}}</h1>
              </router-link>
              <div class="product-quantity">
                <p>Rs {{product_obj.price_out}}</p>
                <span v-if="product_obj.unit">per {{product_obj.unit.name}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProductPreviewModal
      :tag="single_product"
      v-show="!isPreviewModalVisible"
      @close="isPreviewModalVisible = !isPreviewModalVisible"
    />
    <AddToCartModal
      :tag_cart="single_product_cart"
      v-show="!isCartModalVisible"
      @close="isCartModalVisible = !isCartModalVisible"
    />
    <ModalWarning
      :tag="response_message"
      v-show="isModalWarningVisible"
      @close="isModalWarningVisible = !isModalWarningVisible"
    />
    <ModalSuccess
      :tag="cart_success_message"
      v-show="isModalSuccessVisible"
      @close="isModalSuccessVisible = !isModalSuccessVisible"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ProductPreviewModal from "../components/ProductPreviewModal.vue";
import AddToCartModal from "../components/AddToCartModal.vue";
import ModalWarning from "../components/ModalWarning.vue";
import ModalSuccess from "../components/ModalSuccess.vue";

export default {
  name: "AllProducts",
  components: {
    ProductPreviewModal,
    AddToCartModal,
    ModalWarning,
    ModalSuccess,
  },
  computed: {
    ...mapState([
      "categories_list",
      "product_list",
      "error_message",
      "response_message",
    ]),
  },
  created() {
    this.listCategories();
    this.listProducts();
  },
  watch: {

    '$route.params.id'() {
      if (this.$route.params.id != 'All') {
        this.CategoryProduct(this.$route.params.id)
      }else{
        this.GetAllCategoryList()
      }
    },

    search_product: function () {
      if (this.search_product.length > 0) {
        this.setProductList(this.search_product);
      }
    },
    //
    product_list: function () {
      var url_params = this.$route.params.id;
      console.log("URL Params", url_params)
      if (url_params != "All") {
        var category_obj = this.categories_list.find(
          (evt) => evt.name == url_params
        );
        if(category_obj.product_category && category_obj.product_category.length > 0){
          this.setProductList(category_obj.product_category);
        }
      }
      else{
        this.setProductList(this.product_list);
      }
    },
  },

  mounted: function() {
    var url_params = this.$route.params.id
    if (url_params!="All") {
      var category_obj = this.categories_list.find(
        evt => evt.name == url_params
      );
      this.setProductList(category_obj.product_category);;
    }
  },
  data() {
    return {
      select_category: "",
      isPreviewModalVisible: true,
      isCartModalVisible: true,
      isModalSuccessVisible: false,
      isModalErrorVisible: false,
      isModalWarningVisible: false,
      single_product: [],
      single_product_cart: [],
      isModalSuccessVisible: false,
      selected_unit_obj: '',
      cart_success_message: "Product Successfully Added in Cart."
    };
  },
  methods: {
    ...mapActions(["listCategories", "listProducts"]),
    ...mapMutations(["setProductList"]),

    checkValidCategory(categoryObj){
      let haveActiveProduct = false

      let allCategoryList = categoryObj.product_category
      allCategoryList.forEach((item) => {
        if (!haveActiveProduct) {
          let checkOutOfStock = false
          let checkNoInventry = false
          checkOutOfStock = (item.product_status == "Available") ? true : false
          checkNoInventry = parseInt(item.total_quantity) > 1 ? true : false

          if (checkOutOfStock && checkNoInventry) { haveActiveProduct = true }
        }
      })

      return haveActiveProduct
    },

    CategoryProduct(category_name) {
      this.select_category = category_name

      var category_obj = this.categories_list.find(
        (evt) => evt.name == category_name
      );
      if(category_obj.product_category && category_obj.product_category.length > 0){
        this.setProductList(category_obj.product_category);
      }
      // this.$router.push({
      //   name: "allproducts",
      //   params: { id: category_name },
      // });
      
    },
    GetAllCategoryList(){
      this.select_category = 'All'
      this.listProducts()
      this.setProductList(this.product_list)
    },
    isPreviewModal(product_id) {
      this.single_product = this.product_list.find(
        (evt) => evt.id == product_id
      );

      this.isPreviewModalVisible = false;
    },
    addToCartModal(product) {
      //
      this.selected_unit_obj = product.product_unit.find(
          evt => evt.unit.id == product.unit.id
        );
        let cartItem = {
          'id': product.id,
          'name': product.name,
          'unit': this.selected_unit_obj.unit.id,
          'unitname': this.selected_unit_obj.unit.name,
          'image': product.image,
          'quantity': 1,
          'price': product.price_out,
          'update': false
        };
      // let cartItem = {
      //   id: product.id,
      //   name: product.name,
      //   image: product.image,
      //   quantity: 1,
      //   price: product.price_out,
      //   update: false,
      // };
      this.$store.commit("addUpdateToCart", cartItem);
      if (this.error_message == "errormessage") {
        this.isModalWarningVisible = true;
      }else{
        this.isModalSuccessVisible = true
      }
    },
  },
};
</script>

<style lang="scss">
</style>
