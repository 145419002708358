<template>
  <div class="help-page gg-container">
    <article>
      <h2>Green Growth team is always ready for help</h2>
      <p>Green Growth has a unique story of how it started. It was incubated inside the Story Cycle which is a multimedia and map-based storytelling organization.</p>
      <h3>Available Queries</h3>
      <ul>
        <li>From Shinja Farm, Jumla we delivered walnuts, lentils, Marshi Rice, Apple Brandy, Dried apples, Organic teas, herbs like jimbu etc to the consumer.</li>
        <li>From Sindhuli Farm we delivered Fruits like lychees, Junar, Mangoes, Jackfruits etc to the consumer.</li>From Patale Gaun Farm, Kavre we delivered different organic vegetables and local organic products to the consumer.
        <li>From Gaun Ghar Organic Farm, Khopasi we delivered different fruits like Kiwi, Strawberries, Oranges etc.</li>
        <li>From Laxmaniya Farm, Mahottari we delivered different lentils, rice variety, mangoes, pickles etc.</li>
        <li>From Manglung Farm, Ilam we delivered Green teas and Ghee butter etc.</li>
        <li>From Manang, Mustang for apple</li>
        <li>From Amare Nuwakot, Shiitake</li>
        <li>From Biratnagar Pulses and Litchis</li>
        <li>From Kakani strawberries</li>
        <li>From Rosa Farming concept, Dolpa Saffron, walnuts</li>
        <li>From Myagdi, Timur and Lasun Chop</li>
        <li>From Dang, Garlic cloves</li>
        <li>From Ashapuri: Sitake, avocado, chia seed</li>
        <li>From R and D Solution: Beans</li>
        <li>From Khotang, Black lentil</li>
        <li>From Shreepur, Saptari: Mangoes</li>
        <li>From Dhye, Musang: Dewa Apples</li>
        <li>From Saugat Sindhuli: Lentils, Flour and homemade products</li>
      </ul>
    </article>
    <!-- <article class="query-form">
      <h3>Ask For Query</h3>
      <textarea rows="10" cols="50" placeholder="Write Here ...">
      </textarea>
      <input class="submit-btn" type="submit" value="Submit" />
    </article> -->
  </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>

<style>
/* css is at _about_page.scss */
</style>
