<template>
<div class="gg-container">
  <ContinueButton/>
   <ProductOnCart/>
</div>
</template>

<script>
import ContinueButton from '../components/ContinueButton.vue';
import ProductOnCart from '../components/ProductOnCart.vue';

export default {
  name: 'MyCartProducts',
  components: {
    ContinueButton,
    ProductOnCart,
  },
};
</script>

<style>

</style>
