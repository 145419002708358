<template>
  <section class="home-categories-show-case">
    <div class="gg-container">
      <h1 class="section-heading">You may also like</h1>
      <div class="card-wrapper">
        <!-- <ProductCard>
        </ProductCard> -->
        <div class="product-card" v-for="(product_obj,index) in product_list.slice(0,5)" :key="index" v-if="product_obj.product_status == 'Available' && product_obj.total_quantity > 0">
          <div class="product-image" id="product-details">
          <router-link
            :to="{name: 'productdetails', params: {id: product_obj.id}}"
            class="product-description"
          >
            <img :src="product_obj.image" alt="image" />
          </router-link>
          <div class="preview-product-icons">
            <button class="preview-icon" @click="isPreviewModal(product_obj.id)">
              <i class="far fa-eye"></i>
            </button>
            <button class="add-to-cart-icon" @click="addToCartModal(product_obj)">
              <i class="fas fa-shopping-bag"></i>
            </button>
          </div>
        </div>
        <div class="description-card">
          <router-link
            :to="{name: 'productdetails', params: {id: product_obj.id}}"
            class="product-description"
          >
            <h1>{{product_obj.name}}</h1>
          </router-link>
          <div class="product-quantity">
            <p>Rs {{product_obj.price_out}}</p>
            <span>per {{product_obj.unit.name}}</span>
          </div>
        </div>
    </div>

      <ProductPreviewModal
        :tag="single_product"
        v-show="!isPreviewModalVisible"
        @close="isPreviewModalVisible = !isPreviewModalVisible"
      />
      <AddToCartModal
        :tag_cart="single_product_cart"
        v-show="!isCartModalVisible"
        @close="isCartModalVisible = !isCartModalVisible"
      />
    </div>
    <ModalWarning
      :tag="response_message"
      v-show="isModalWarningVisible"
      @close="isModalWarningVisible = !isModalWarningVisible"
    />
    <ModalSuccess
      :tag="cart_success_message"
      v-show="isModalSuccessVisible"
      @close="isModalSuccessVisible = !isModalSuccessVisible"
    />
        
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ProductPreviewModal from "./ProductPreviewModal.vue";
import AddToCartModal from "./AddToCartModal.vue";
import ModalWarning from "../components/ModalWarning.vue";
import ModalSuccess from "../components/ModalSuccess.vue";
export default {
  name: 'SuggestedProducts',
  components: {
    ProductPreviewModal,
    AddToCartModal,
    ModalWarning,
    ModalSuccess,
  },
  computed: {
    ...mapState(["product_list","error_message","response_message",]),
  },

  watch: {
    product_list: function(){
      if(this.$route.params.id){
        this.product_details = this.product_list.find(evt => evt.id == this.$route.params.id);
        this.category_id=this.product_details.category;
        this.suggested_product = this.product_list.find(evt => evt.category== this.category_id);
      }
    },
  },
  created() {
    this.listProducts();
  },
  methods: {
    ...mapActions(['listProducts']),
    checkValidCategory(categoryObj){
      let haveActiveProduct = false

      let allCategoryList = categoryObj.product_category
      allCategoryList.forEach((item) => {
        if (!haveActiveProduct) {
          let checkOutOfStock = false
          let checkNoInventry = false
          checkOutOfStock = (item.product_status == "Available") ? true : false
          checkNoInventry = parseInt(item.total_quantity) > 1 ? true : false

          if (checkOutOfStock && checkNoInventry) { haveActiveProduct = true }
        }
      })

      return haveActiveProduct
    },
    isPreviewModal(product_id) {
      this.single_product = this.product_list.find(
        (evt) => evt.id == product_id
      );

      this.isPreviewModalVisible = false;
    },
    addToCartModal(product){
      //
        let cartItem = {
          'id': product.id,
          'name': product.name,
          'image': product.image,
          'quantity': 1,
          'price': product.price_out,
          'update': false
        };
        this.$store.commit('addUpdateToCart', cartItem);
        if (this.error_message == "errormessage") {
          this.isModalWarningVisible = true
        }else{
          this.isModalSuccessVisible = true
        }
    },
  },
  data() {
    return {
      show: false,
      product_details:[],
      category_id:"",
      suggested_product:[],
      isPreviewModalVisible: true,
      isCartModalVisible: true,
      single_product: [],
      single_product_cart: [],
      isModalWarningVisible: false,
      isModalSuccessVisible: false,
      cart_success_message: "Product Successfully Added in Cart."
    }
  },

};
</script>

<style>
</style>
