<template>
  <div class="billing-card">
    <div class="billing-purchase-card">
      <div class="your-order-section">
        <h1 class="your-order-area-heading">your order</h1>
          {{user_profile}}
        <div class="calculation-heading">
          <span>product</span>
          <span>quantity</span>
          <span>price</span>
        </div>
        <div class="product-info">
          <span class="product-name">{{product_details['name']}}</span>
          <span class="quantity-inc-dec">
            <button href="#">&laquo;</button>
            <input type="number" class="quantity-input" v-model="quantity_nunber"/>
            <button href="#">&raquo;</button>
          </span>
          <span>NPR. {{product_details['price_out']}}.00</span>
        </div>
        <div class="total-info">
          <span>Total</span>
          <span class="text-color-red">NPR. {{product_details['price_out']*quantity_nunber}}.00</span>
        </div>
      </div>
      <div class="customer-login-section">
        <div class="customer-register-box" v-if="token==''">
          <div class="log-in-section">
            <span class="white-text">already a customer ?</span>
            <button class="button" type="submit" id="log-in">
              <span>Log In</span>
            </button>
          </div>
          <div class="or-section">
            <span>or</span>
          </div>
          <div class="register-button-section">
            <button class="button" type="submit" id="join">
              <span>register</span>
            </button>
          </div>
        </div>
        <div class="billing-info-heading">
          <h1>billing information</h1>
        </div>
        <form id="contactform">
          <div class="name">
            <div class="first-name">
              <input
                id="firstName"
                v-model="first_name"
                placeholder="First name"
                required
                tabindex="1"
                type="text"
              />
              <label for="firstName">First Name</label>
            </div>
            <div class="last-name">
              <input
                id="lastName"
                v-model="last_name"
                placeholder="last name"
                required
                tabindex="1"
                type="text"
              />
              <label for="lastName">Last Name</label>
            </div>
          </div>
          <div class="contact">
            <div class="email">
              <input
                id="email"
                v-model="email"
                placeholder="example@domain.com"
                required type="email"
              />
              <label for="email">Email</label>
            </div>
            <div class="phone">
              <input
                id="phone"
                v-model="phone"
                placeholder="phone number"
                required type="text"
              />
              <label for="phone">Mobile phone</label>
            </div>
          </div>
          <div class="select-zone">
            <select class="zone" v-model="province_selected">
              <option value disabled>Select Province</option>
              <option  v-for="(province, index) in provinces" :key="index" :value="province.name">{{ province.name }}</option>
            </select>
          </div>
          <div class="select-country">
            <select class="country" v-model="district_selected">
              <option value disabled>Select District</option>
              <option v-for="(district,index) in districts" :key="index" :value="district">{{ district }}</option>
              <!-- <option value="f">India</option>
              <option value="others">China</option> -->
            </select>
          </div>

          <div class="address-field">
            <div class="street-address-name-field">
              <input
                id="streetAddress"
                v-model="country"
                placeholder="your current street.."
                required
                tabindex="1"
                type="text"
                disabled
              />
              <label for="streetAddress">Country</label>
            </div>
            <div class="city-address-name-field">
              <input
                id="cityAddress"
                v-model="street_address"
                placeholder="your Street Address.."
                required
                tabindex="1"
                type="text"
              />
              <label for="cityAddress">Street Address</label>
            </div>
          </div>
          <div class="cash-on-delivery">
            <h1>cash on delivery</h1>
            <p>
              Pay with cash upon delivery.
              <br />Your personal data will be used to process your order,
              <br />support your experience throughtout this website and
              <br />for other purposes described in our
              <a href="#">privacy policy</a>
              <br />
            </p>
          </div>
          <div class="agreement-field">
            <input type="checkbox" id="agreement" name="agreed" value="checkbox" />
            <label for="checkbox">
              I have read and agree to the website
              <span>
                <a href>terms and privacy</a>
              </span>
            </label>
            <br />
          </div>
          <div class="submit-button">
            <button type="button" class="button" @click="PlaceOrder">Place Order</button>
          </div>
        </form>
      </div>
    </div>

    <ModalSuccess
    :tag="response_success"
    v-show="isModalSuccessVisible" @close="isModalSuccessVisible = !isModalSuccessVisible" />

    <ModalError
    :tag="error_response"
    v-show="isModalErrorVisible" @close="isModalErrorVisible = !isModalErrorVisible" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import province from '../js/districts.js';
import ModalSuccess from "../components/ModalSuccess.vue";
import ModalError from "../components/ModalError.vue";
export default {
  name: 'BillingInformation',
  components: {
    ModalSuccess,
    ModalError
  },

  computed: {
    ...mapState(['token','user_profile','product_list','user_register_message','error_message']),

    districts: function(){
      if(this.province_selected != ""){
        return this.provinces.find(province => province.name == this.province_selected ).districts
      }
      else{
        return "";
      }
    },
  },
  created() {
    this.listProducts();
    this.listProfile();

  },

  watch: {
    user_profile: function(){
      if(this.user_profile){
        console.log("prabin");
        console.log(this.user_profile);
        this.first_name = this.user_profile['first_name']
        this.last_name = this.user_profile['last_name']
        this.phone = this.user_profile['phone_number']
        this.email = this.user_profile['email']


      }
  },


  product_list: function() {
    if (this.$route.params.id) {
      this.product_details = this.product_list.find(
        evt => evt.id == this.$route.params.id
      );
    }
  },



  },



  data() {
    return {
      first_name: '',
      last_name: '',
      country:'Nepal',
      phone:'',
      email:'',
      street_address:'',
      district_selected:'',
      province_selected:'',
      provinces: province,
      errors: {'auth':''},
      product_details:[],
      quantity_nunber:1,
      total_price :'',
      isModalSuccessVisible:false,
      isModalErrorVisible:false,
      response_success:"You have successfuly order your products.",
      error_response:"You have to login to order a product."
    };
  },
  methods: {
    ...mapActions(['listProfile','listProducts']),

    PlaceOrder(){
      this.$store.dispatch("createOrder", {
        'full_name':this.first_name+""+this.last_name,
        'country': this.country,
        'email':this.email,
        'province': this.province_selected,
        'quantity': this.quantity_nunber,
        'phone':this.phone,
        'product':this.product_details['id'],
        'city':this.district_selected,
        'street':this.street_address
      }).then(() => {
        if(this.user_register_message=='success'){
          this.isModalSuccessVisible=true;
          location.reload();
        }else if(this.error_message=="errormessage"){
          this.isModalErrorVisible = true;
        }
      })
    }
  },
};
</script>

<style>
</style>
