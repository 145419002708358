import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import Routes from "./routes";
import axios from "axios";
import BootstrapVue from "bootstrap-vue";
import VueMaterial from "vue-material";
import "leaflet/dist/leaflet.css";
// import "@/assets/scss/style.scss";
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

import { createStore } from "./store";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@fortawesome/fontawesome-free/css/all.css";
import L from "leaflet";

// import VueSplide from "@splidejs/vue-splide";
// Vue.use(VueSplide);

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

import LControlPolylineMeasure from "vue2-leaflet-polyline-measure";
Vue.component("l-control-polyline-measure", LControlPolylineMeasure);
Vue.use(BootstrapVue);
Vue.use(VueMaterial);
// Optionally install the BootstrapVue icon components plugin

Vue.config.productionTip = false;
Vue.use(VueRouter);

axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL;

const router = new VueRouter({
  routes: Routes,
  mode: "history",
});
const store = createStore();

const eventBus = new Vue();
export { eventBus as default };

new Vue({
  store,
  router: router,
  // PulseLoader,
  render: (h) => h(App),
}).$mount("#app");
