<template>
<div id="app">
  <HomeBanner></HomeBanner>
  <HomeExclusive></HomeExclusive>
  <!-- <div class="container"> -->
  <HomeCategoriesShowCase/>
  <!-- <HomeVegetables></HomeVegetables> -->

  <!-- </div> -->
</div>
</template>

<script>
// import { mapState,mapMutations } from 'vuex';

import HomeBanner from '../components/HomeBanner.vue';
import HomeExclusive from '../components/HomeExclusive.vue';
import HomeCategoriesShowCase from '../components/HomeCategoriesShowCase.vue';
// import HomeVegetables from '../components/HomeVegetables.vue';

// const axios = require('axios');
export default {
  name: 'HomePage',
  components: {
    HomeBanner,
    HomeExclusive,
    HomeCategoriesShowCase,
    // HomeVegetables,
  },
};
</script>

<style lang="scss" scoped>
  // @import "../css/homepage.scss";

</style>
