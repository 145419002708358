<template>
  <div class="alert-modal" @click="$emit('close')">
    <div class="modal-box" @click.stop>
      <div class="close-modal" @click="$emit('close')">
        <i class="fas fa-times"></i>
      </div>
      <div class="modal-content">
        <div class="message-icon icon-warning">
          <span>
            <i class="fas fa-exclamation"></i>
          </span>
        </div>
        <div class="modal-message message-warning" v-if="tag">
          {{tag}}
        </div>

        <div class="modal-message message-warning" v-else>
          Products Already in Cart.
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalWarning',
  props:["tag"],
};
</script>

<style>
</style>
