<template>
  <div class="contact-page gg-container">
    <h2>Activate User Registration</h2>

    <div class="gg-contact">
      <address>
        <p>
          We send you an activation code to your email. Please use this code to
          activate your registration process.
        </p>
        <form method="POST">
          <div class="name">
            <div class="activation activation-code form-input-wrap">
              <label for="name"><strong>Token Code</strong></label
              ><br />
              <input
                id="activation-code"
                v-model.trim="token"
                placeholder="Insert Token Code"
                required
                tabindex="1"
                minlength="6"
                maxlength="6"
                type="text"
                style="background: #dcdcdc; font-size: 18px; padding: 10px"
              />
              <button
                type="button"
                class="button"
                @click="activateUserNow"
              >
                Activate Now</button
              ><br />
              <span style="color: red" v-if="errors.token">{{
                errors.token
              }}</span>
            </div>
          </div>
        </form>
      </address>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex";
export default {
  name: "ActiveateUserRegister",
  data() {
    return {
      token: "",
      errors: [],
    };
  },
  computed: {
    ...mapState([
      "successmessage",
      "error_message",
      "message",
      "response_message",
      "success_message",
    ]),
  },
  mounted() {
    $(".create-body").show();
    if(!localStorage.getItem('activatePageReloaded')){
      localStorage.setItem('activatePageReloaded', true);
      location.reload();
    }
  },
  methods: {
    activateUserNow(e) {
      e.preventDefault();
      this.errors = [];
      if (this.token == "") {
        this.errors["token"] = "Token required";
      } else if (this.token.length != 6) {
        this.errors["token"] = "Token must have 6 characters.";
      } else {
        let formData = new FormData();
        formData.append("token", this.token);

        for (var pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        this.$store.dispatch("activateUser", formData).then(() => {
          if (this.success_message == "success") {
            localStorage.removeItem('activatePageReloaded')
            alert("User Activate Successfully.\nPlease Login Now.");
            this.$router.push("/");
          } else if (this.error_message == "errormessage") {
            alert(this.response_message);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-form {
  display: none;
}

.activation {
	button {
		background: #3b8154;
		font-size: 16px;
		padding: 12px;
		color: #fff;
		&:hover {
			background: #1a5c31;
		}
	}
}
</style>