<template>
	<div>
		
	</div>
</template>
<script type="text/javascript">
import Baato from "@klltech/baato-js-client";
export default{
	name: 'BatoPage',
	data() {
		return {
			//
		}
	},
	created() {
		//Search API
		new Baato.Search()
		.setApiVersion("1")
		.setQuery("Balkot Chowk")
		.setKey("bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu")
		.doRequest()
		.then(response => {
			console.log("One: ", response); // search response
			// console.log("Two: ", response.data); // search response
			// console.log("Three: ", response.data[1]); // search response
			// console.log("Four: ", response.data[1].placeId); // search response
			//let firstPlaceId = response.data[0].placeId
			// new Baato.Reverse()
			// .setApiVersion("1.0") 
			// .setPlaceId(firstPlaceId)
			// .setKey("bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu")
			// .doRequest()
			// .then(res => {
			// const geojson = new Baato.Util().getGeoJsonFromSearchResults(res);
			// 	console.log(geojson);
			// });
		});

		// Map API
		// const search = new Baato.Places()
		// .setApiVersion("1.0") // default
		// .setPlaceId("110023")
		// .setKey("bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu")
		// .doRequest()
		// .then(response => {
		// 	console.log(response); // place response
		// });

		//Reverse API
		// const reverse = new Baato.Reverse()
		// .setApiVersion("1.0") 
		// .setLat("27.7172")
		// .setLon("85.324")
		// .setKey("bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu")
		// .doRequest()
		// .then(res => {
		// 	const geojson = new Baato.Util().getGeoJsonFromSearchResults(res);
		// 	console.log(geojson); // reverse-search response
		// });

		//For Routing
		// const points = ["27.71772,85.32784", "27.73449,85.33714"];
		// const bRouting = new Baato.Routing({
		// 	key: "bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu"
		// })
		// .setApiVersion("1.0")  // default
		// .addPoints(points)
		// .setVehicle("car")
		// .getBest()
		// .doRequest()
		// .then(response => {
		// 	console.log(JSON.stringify(response));
		// });
	}
}
</script>