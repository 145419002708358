<template>
  <div class="product-add-to-cart-modal" v-if = "tag_cart['category']" @click="$emit('close')">
    <div class="modal-content" @click.stop>
      <div class="close-add-to-cart" @click="$emit('close')">
        <div class="success-message">
          <span><i class="fas fa-check"></i> Product succesfully added to cart </span>
        </div>
        <div class="close-modal"><i class="fas fa-times"></i></div>
      </div>
      <div class="product-cart" v-if="tag_cart['image']">
              <div class="modal-image">
        <img :src="tag_cart['image']" alt="image" />
      </div>
      <div class="product-description" v-if="tag_cart">
        <h1>{{tag_cart['name']}}</h1>
        <p class="category">Category : <span> {{tag_cart['category']['name']}} </span></p>
        <p class="quantity">Quantity : <span> 1 </span>
        </p>
        <p class="price">Total. NPR {{tag_cart['price_out']}}.00</p>
        <router-link to="/all-products" class="continue-shopping">
          <span>Continue shopping</span>
        </router-link>
        <router-link :to="{name: 'checkoutPage'}" class="check-out">
          <span>Proceed to checkout</span>
        </router-link>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:["tag_cart"],
  name: 'AddToCartModal',
};
</script>

<style>

</style>
