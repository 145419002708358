const axios = require("axios");
let authHeader = "JWT " + localStorage.getItem("token");


export default {
  searchProducts({ commit }, search_obj) {
    return axios.get("product/?search=" + search_obj, {}).then((response) => {
      commit("setSearchProductList", response.data);
    });
  },
  listCategories({ commit }) {
    axios.get("categories", {}).then((response) => {
      if (response.status == 200) {
        commit("setCategoriesList", response.data);
      }
    });
  },

  listProducts({ commit }) {
    return axios.get("products", {}).then((response) => {
      commit("setProductList", response.data);
    });
  },

  listBanner({ commit }) {
    // axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("banner", {}).then((response) => {
      commit("setBannerList", response.data);
    });
  },

  listBatch({ commit }) {
    // axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios.get("batch", {}).then((response) => {
      commit("setBatchList", response.data);
    });
  },

  listVendorProducts({ commit }, vendorName) {
    return axios.get("productslug/" + vendorName, {}).then((response) => {
      commit("setVendorProductList", response.data);
    });
  },

  listNotifications({ commit }) {
    return axios
      .get("notifications", {
        headers: {
          Authorization: authHeader,
        },
      })
      .then((response) => {
        commit("setNotificationList", response.data);
      });
  },

  listUnSeenNotifications({ commit }) {
    return axios
      .get("unseennotifications", {
        headers: {
          Authorization: authHeader,
        },
      })
      .then((response) => {
        commit("setNotificationList", response.data);
      });
  },

  seenThisNotification({ state, commit }, notification_id) {
    axios.defaults.headers.common["authorization"] = authHeader;
    return axios
      .put("notificationseen/" + notification_id, {})
      .then((response) => {
        commit("setSuccessMessage", "success");
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  seenAllNotificationOfUser({ state, commit }, notification_id) {
    axios.defaults.headers.common["authorization"] = authHeader;
    return axios
      .put("notificationallseen", {})
      .then((response) => {
        commit("setSuccessMessage", "success");
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listUsers({ commit }) {
    // axios.defaults.headers.common["authorization"] = authHeader;
    return axios
      .get("users", {
      })
      .then((response) => {
        commit("setUserList", response.data);
      });
  },

  createUser({ state, commit }, user_obj) {
    return axios
      .post("users", user_obj)
      .then((response) => {
        commit("setUserRegisterMessage", "success");
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  activateUser({ state, commit }, user_obj) {
    return axios
      .post("users/activate", user_obj)
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        } else {
          commit("setErrorMessage", "errormessage");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  forgetPassword({ state, commit }, user_obj) {
    return axios
      .post("users/forgetpassword", user_obj)
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        } else {
          commit("setErrorMessage", "errormessage");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  resetPassword({ state, commit }, user_obj) {
    return axios
      .post("users/resetpassword", user_obj)
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        } else {
          commit("setErrorMessage", "errormessage");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  createOrder({ state, commit }, order_obj) {
    return axios
      .post("orders", order_obj, {
        headers: {
          Authorization: authHeader,
        },
      })
      .then((response) => {
        commit("setSuccessMessage", "success");
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listProfile({ commit }) {
    // axios.defaults.headers.common['authorization'] = 'JWT '+ this.state.token
    return axios
      .get("users/profile", {
        headers: {
          Authorization: authHeader,
        },
      })
      .then((response) => {
        commit("setUserprofile", response.data);
      });
  },

  // listCartProducts({commit}) {
  //   // axios.defaults.headers.common['authorization'] = 'JWT '+ this.state.token
  //   return axios
  //     .get('carts', {
  //       headers: {
  //           'Authorization': authHeader
  //       }
  //     })
  //   .then(response => {
  //     commit('setCartProductList',response.data)
  //   })
  // },

  // addToCart({state,commit}, cart_obj){
  //   // axios.defaults.headers.common['authorization'] = 'JWT '+ this.state.token
  //   return axios
  //     .post('carts', cart_obj,{
  //       headers: {
  //         'Authorization': authHeader
  //     }})
  //     .then(response => {
  //       commit("setCartSuccessMessage", "success");
  //     })
  //     .catch(error=>{
  //       if (error){
  //         commit("setErrorMessage", 'errormessage')
  //         commit("setMessage",error.response.data.message);
  //       }
  //     })
  // },

  // updateToCart({state,commit}, cart_id, cart_data){
  //   // axios.defaults.headers.common['authorization'] = 'JWT '+ this.state.token
  //   return axios
  //     .post(`carts/${cart_id}`, cart_data, {
  //       headers: {
  //         'Authorization': authHeader
  //     }})
  //     .then(response => {
  //       commit("setSuccessMessage", "success");
  //     })
  //     .catch(error=>{
  //       if (error){
  //         commit("setErrorMessage", 'errormessage')
  //         commit("setMessage", error.response.data.message);
  //       }
  //     })
  // },

  // removeFromCart({state,commit}, cart_id){
  //   // axios.defaults.headers.common['authorization'] = 'JWT '+ this.state.token
  //   return axios
  //     .delete(`carts/${cart_id}` ,{
  //       headers: {
  //         'Authorization': authHeader
  //     }})
  //     .then(response => {
  //       commit("setSuccessMessage", "success");
  //     })
  //     .catch(error=>{
  //       if (error){
  //         commit("setErrorMessage", 'errormessage')
  //         commit("setMessage",error.response.data.message);
  //       }
  //     })
  // },

  createDeliveryAddress({ state, commit }, address_obj) {
    axios.defaults.headers.common["authorization"] = authHeader;
    return axios
      .post("deliveryaddress", address_obj)
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          commit("setDeliveryAddressList", response.data);
        } else {
          commit("setErrorMessage", "errormessage");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listDeliveryAddress({ commit }) {
    return axios
      .get("deliveryaddress", {
        headers: {
          Authorization: authHeader,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          commit("setDeliveryAddressList", response.data);
        } else {
          commit("setErrorMessage", "errormessage");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  createNewOrder({ state, commit }, order_obj) {
    axios.defaults.headers.common["authorization"] = authHeader;
    return axios
      .post("orders", order_obj)
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        } else {
          commit("setErrorMessage", "errormessage");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listProductRating({ commit }) {
    return axios
      .get("productrating", {
        headers: {
          Authorization: authHeader,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          commit("setProductRatingList", response.data);
        } else {
          commit("setErrorMessage", "errormessage");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  createProductRating({ state, commit }, review_obj) {
    axios.defaults.headers.common["authorization"] = authHeader;
    return axios
      .post("productrating", review_obj)
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        } else {
          commit("setErrorMessage", "errormessage");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listStaticPageData({ commit }, pageSlug) {
    return axios.get("flatpage/" + pageSlug, {}).then((response) => {
      commit("setStaticPageList", response.data);
    });
  },

  listMyBillingAddress({ commit }) {
    return axios
      .get("mydeliveryaddress", {
        headers: {
          Authorization: authHeader,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          commit("setBillingAddressList", response.data);
        } else {
          commit("setErrorMessage", "errormessage");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },
};
