<template>
  <div class="alert-modal" @click="$emit('close')">
    <div class="modal-box" @click.stop>
      <div class="close-modal" @click="$emit('close')">
        <i class="fas fa-times"></i>
      </div>
      <div class="modal-content">
        <div class="message-icon icon-success">
          <span>
            <i class="fas fa-check"></i>
          </span>
        </div>
        <div class="modal-message message-success">
          {{tag}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalSuccess',
  props:["tag"],
  mounted(){
    //
  }
};
</script>

<style>
</style>
