<template>
  <div class="featured-categories">
    <loading 
			:active.sync="isLoading" 
			:can-cancel="true">
		</loading>
    <section
      class="home-categories-show-case"
      v-for="(category_obj,index) in categories_list"
      :key="index"
      v-if="category_obj.name.toLowerCase()!='exclusive' && category_obj.product_category.length>0"
    >
      <div class="gg-container" v-if="checkValidCategory(category_obj)">
        <div class="section-heading">
          <h2>{{category_obj.name}}</h2>
          <router-link v-if="checkSeeMoreBtn(category_obj.product_category)" :to="'/all-products/'+category_obj.name">
            <span class="see-more">see more</span>
          </router-link>
        </div>

        <div class="card-wrapper" v-if="category_obj.product_category">
          
          <div class="product-card" v-for="(product_obj,index) in filterCategoryProductList(category_obj.product_category)" :key="index" v-if="product_obj.product_status == 'Available' && product_obj.total_quantity > 0">
                <div class="product-image" id="product-details">
                <router-link
                  :to="{name: 'productdetails', params: {id: product_obj.id}}"
                  class="product-description"
                >
                  <img :src="product_obj.image" alt="image" />
                </router-link>
                <div class="preview-product-icons">
                  <button class="preview-icon" @click="isPreviewModal(product_obj.id)" title="Preview">
                    <i class="far fa-eye"></i>
                  </button>
                  <button class="add-to-cart-icon" @click="addToCartModal(product_obj)" title="Add Cart">
                    <i class="fas fa-cart-plus"></i>
                  </button>
                </div>
              </div>
              <div class="description-card">
                <router-link
                  :to="{name: 'productdetails', params: {id: product_obj.id}}"
                  class="product-description"
                >
                  <h1>{{product_obj.name}}</h1>
                </router-link>
                <div class="product-quantity">
                  <p>Rs {{product_obj.price_out}}</p>
                  <span>per {{product_obj.unit.name}}</span>
                </div>
              </div>
          </div>

        </div>
      </div>
    </section>
          <ProductPreviewModal
            :tag="single_product"
            v-show="!isPreviewModalVisible"
            @close="isPreviewModalVisible = !isPreviewModalVisible"
          />
          <AddToCartModal
            :tag_cart="single_product_cart"
            v-show="!isCartModalVisible"
            @close="isCartModalVisible = !isCartModalVisible"
          />
        <ModalWarning
          :tag="response_message"
          v-show="isModalWarningVisible"
          @close="isModalWarningVisible = !isModalWarningVisible"
        />
        <ModalSuccess
          :tag="cart_success_message"
          v-show="isModalSuccessVisible"
          @close="isModalSuccessVisible = !isModalSuccessVisible"
        />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ProductPreviewModal from "./ProductPreviewModal.vue";
import AddToCartModal from "./AddToCartModal.vue";
import ModalWarning from "../components/ModalWarning.vue";
import ModalSuccess from "../components/ModalSuccess.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "HomeCategoriesShowCase",
  components: {
    Loading,
    ProductPreviewModal,
    AddToCartModal,
    ModalWarning,
    ModalSuccess,
  },
  data() {
    return {
      isLoading: true,
      isPreviewModalVisible: true,
      isCartModalVisible: true,
      single_product: [],
      single_product_cart: [],
      isModalWarningVisible: false,
      isModalSuccessVisible: false,
      selected_unit_obj: '',
      cart_success_message: "Product Successfully Added in Cart."
    };
  },
  computed: {
    ...mapState([
      "categories_list",
      "product_list",
      "error_message",
      "response_message",
    ]),
  },
  created() {
    this.listCategories();
    this.listProducts();
  },
  methods: {
    ...mapActions(["listCategories", "listProducts"]),
    checkValidCategory(categoryObj){
      let haveActiveProduct = false

      let allCategoryList = categoryObj.product_category
      allCategoryList.forEach((item) => {
        if (!haveActiveProduct) {
          this.isLoading = false
          let checkOutOfStock = false
          let checkNoInventry = false
          checkOutOfStock = (item.product_status == "Available") ? true : false
          checkNoInventry = parseInt(item.total_quantity) > 1 ? true : false

          if (checkOutOfStock && checkNoInventry) { haveActiveProduct = true }
        }
      })

      return haveActiveProduct
    },
    isPreviewModal(product_id) {
      this.single_product = this.product_list.find(
        (evt) => evt.id == product_id
      );

      this.isPreviewModalVisible = false;
    },
    addToCartModal(product){
      //
        this.selected_unit_obj = product.product_unit.find(
          evt => evt.unit.id == product.unit.id
        );
        let cartItem = {
          'id': product.id,
          'name': product.name,
          'unit': this.selected_unit_obj.unit.id,
          'unitname': this.selected_unit_obj.unit.name,
          'image': product.image,
          'quantity': 1,
          'price': product.price_out,
          'update': false
        };
        
        this.$store.commit('addUpdateToCart', cartItem);
        if (this.error_message == "errormessage") {
          this.isModalWarningVisible = true
        }else{
          this.isModalSuccessVisible = true
        }
    },
    filterCategoryProductList(product_list_obj){
      let product_data = product_list_obj
        product_data = product_data.filter(row => {
          return row.product_status == "Available" && row.total_quantity > 0
        });
      return product_data.slice(0,4)
    },
    checkSeeMoreBtn(product_list_obj){
        let product_data = product_list_obj
        product_data = product_data.filter(row => {
          return row.product_status == "Available" && row.total_quantity > 0
        });
        
        return product_data.length > 4 ? true : false
    }
  },
};
</script>

<style>
</style>
