<template>
  <div id="app">
    <Header></Header>
    <main class="page-body">
      <router-view></router-view>
    </main>
    <Footer></Footer>
    <!-- <LoginForm/> -->
    <!-- <ProductPreviewModal v-show="!isModalVisible" @close="closeModal" /> -->
    <!-- <AddToCartModal v-show="!isModalVisible" @close="closeModal" /> -->
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
// import ProductPreviewModal from './components/ProductPreviewModal.vue';
// import AddToCartModal from './components/AddToCartModal.vue';
import modalMixin from "./mixins/modal";
// import LoginForm from './components/LoginForm.vue';

export default {
  name: "app",
  components: {
    // "login-page": LoginPage,
    Header,
    Footer,
    // ProductPreviewModal,
    // AddToCartModal,
    // LoginForm,
  },
  data() {
    return {
      // 'auth': true
      // showProductPreviewModal: true,
      // isModalVisible: true,
      dialog: false,
    };
  },
  mounted: function() {
      console.log("VUE_APP_BASE_URL", process.env.VUE_APP_BASE_URL);
      console.log("VUE_APP_API_BASE_IP", process.env.VUE_APP_API_BASE_IP);
      console.log("VUE_APP_SERVICE_URL", process.env.VUE_APP_SERVICE_URL);
      console.log("VUE_APP_USER_PANEL", process.env.VUE_APP_USER_PANEL);
      console.log("VUE_APP_ADMIN_PANEL", process.env.VUE_APP_ADMIN_PANEL);
  },
  methods: {},
  mixins: [modalMixin],
  // mounted: function() {

  // }
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
</style>
