<template>
  <div v-if="vendor_product_list && vendor_product_list.length > 0">
    <!-- <div class="gg-container vendor-page">
      <h2>
        Vendor Details
      </h2>
      <div class="shopping-card">
        <div class="shopping-description-card">
          <div class="image-product-description">
            <div class="image">
              <div class="big-image">
                <img :src="vendor_product_list[0].author.image" alt="Green Growth" />
              </div>
            </div>
            <div class="product-description">
              <h1>{{ vendor_product_list[0].author.full_name }}</h1>
              <div class="product-status">
                <p v-if="vendor_product_list[0].author.user_info">
                  <strong>Address:</strong>
                  <span>{{ vendor_product_list[0].author.user_info.street_address }}, {{ vendor_product_list[0].author.user_info.city }}</span>
                </p>
                <p>
                  <strong>Phone Number:</strong>
                  <span>{{ vendor_product_list[0].author.phone_number }}</span>
                </p>
                <p>
                  <strong>E-mail:</strong>
                  <span>{{ vendor_product_list[0].author.email }}</span>
                </p>
                
                <p>
                  <strong>Details: </strong>
                  <span>
                    Ashim have been providing vegetables since 2002. He has a
                    shop in Gokarna and is delivering the products as per
                    orders. He has been subscribing Green Growth to enhance his
                    business and provide fresh vegetables and fruits.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <section class="home-categories-show-case vendor-page">
      <div class="gg-container">
        <h1 class="section-heading">Related Products from Vendor</h1>
        <div class="card-wrapper">
          
          <div class="product-card" 
            v-for="(product_obj,index) in vendor_product_list"
            :key="index"
            v-if="product_obj.product_status == 'Available' && product_obj.total_quantity > 0"
            >
            <div class="product-image" id="product-details">
              <router-link
                :to="{name: 'productdetails', params: {id: product_obj.id}}"
                class="product-description"
              >
              <img :src="product_obj.image" alt="image" />
              </router-link>
              <div class="preview-product-icons">
                <button class="preview-icon" @click="isPreviewModal(product_obj.id)">
                  <i class="far fa-eye"></i>
                </button>
                <button class="add-to-cart-icon" @click="addToCartModal(product_obj)">
                  <i class="fas fa-shopping-bag"></i>
                </button>
              </div>
            </div>
            <div class="description-card">
              <router-link
                :to="{name: 'productdetails', params: {id: product_obj.id}}"
                class="product-description"
              >
                <h1>{{ product_obj.name }}</h1>
              </router-link>
              <div class="product-quantity">
                <p>Rs {{product_obj.price_out}}</p>
                <span>per {{product_obj.unit.name}}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <ProductPreviewModal
      :tag="single_product"
      v-show="!isPreviewModalVisible"
      @close="isPreviewModalVisible = !isPreviewModalVisible"
    />
    <AddToCartModal
      :tag_cart="single_product_cart"
      v-show="!isCartModalVisible"
      @close="isCartModalVisible = !isCartModalVisible"
    />
    <ModalWarning
      :tag="response_message"
      v-show="isModalWarningVisible"
      @close="isModalWarningVisible = !isModalWarningVisible"
    />
    <ModalSuccess
      :tag="cart_success_message"
      v-show="isModalSuccessVisible"
      @close="isModalSuccessVisible = !isModalSuccessVisible"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ProductPreviewModal from "../components/ProductPreviewModal.vue";
import AddToCartModal from "../components/AddToCartModal.vue";
import ModalWarning from "../components/ModalWarning.vue";
import ModalSuccess from "../components/ModalSuccess.vue";

export default {
  name: 'vendor',
  data(){
    return{
      select_category: "",
      isPreviewModalVisible: true,
      isCartModalVisible: true,
      isModalSuccessVisible: false,
      isModalErrorVisible: false,
      isModalWarningVisible: false,
      single_product: [],
      single_product_cart: [],
      isModalSuccessVisible: false,
      cart_success_message: "Product Successfully Added in Cart.",
      vendorProfile: '',
      venderUserName: this.$route.params.slug,
    }
  },
  created() {
    this.listVendorProducts(this.venderUserName);
    //
    // if(this.vendor_product_list && this.vendor_product_list.length > 0){
    //   this.vendorProfile = this.vendor_product_list[0].author
    // }
  },
  components: {
    ProductPreviewModal,
    AddToCartModal,
    ModalWarning,
    ModalSuccess,
  },
  computed: {
    ...mapState([
      "vendor_product_list",
      "error_message",
      "response_message",
    ]),
  },
  mounted(){
    // if(this.vendor_product_list && this.vendor_product_list.length > 0){
    //   this.vendorProfile = this.vendor_product_list[0].author
    // }else{
    //   this.$router.push({
    //     name: 'homepage'
    //   })
    // }
  },
  methods: {
    ...mapActions(["listVendorProducts"]),
    isPreviewModal(product_id) {
      this.single_product = this.vendor_product_list.find(
        (evt) => evt.id == product_id
      );

      this.isPreviewModalVisible = false;
    },
    addToCartModal(product) {
      let cartItem = {
        id: product.id,
        name: product.name,
        image: product.image,
        quantity: 1,
        price: product.price_out,
        update: false,
      };
      this.$store.commit("addUpdateToCart", cartItem);
      if (this.error_message == "errormessage") {
        this.isModalWarningVisible = true;
      }else{
        this.isModalSuccessVisible = true
      }
    },
  },
};
</script>

<style lang="scss">
.vendor-page {
  h2 {
    margin-top: 40px;
  }
  .shopping-card {
    margin-top: 40px;
    .shopping-description-card {
      .image-product-description {
        .image {
          .big-image {
            height: 380px;
          }
        }
      }
    }
  }
  .product-card {
    .product-image {
      height: 200px;
    }
  }
}
@media (max-width: 992px) {
  .vendor-page {
    .shopping-card {
      margin-top: 40px;
      .shopping-description-card {
        .image-product-description {
          flex-wrap: wrap;
          .image {
            width: 100%;
          }
          .product-description {
            width: 100%;
            padding: 0;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .vendor-page {
    .shopping-card {
      margin-top: 40px;
      .shopping-description-card {
        .image-product-description {
          .image {
            margin-bottom: 0;
            .big-image {
              max-width: unset;
            }
          }
        }
      }
    }
  }
}
</style>