 <template>
  <div class="gg-container">
    <loading :active.sync="isLoading" :can-cancel="true"></loading>
    <div class="bread-crumb">
      <ul class="collapsed">
        <router-link to="/">
          <li>Home</li>
        </router-link>
        <router-link
          :to="{
            name: 'allproducts',
            params: { id: product_details.category.name },
          }"
        >
          <li>
            {{ product_details.category.name }}
          </li>
        </router-link>
        <router-link to="/">
          <li>
            {{ product_details.name }}
          </li>
        </router-link>
      </ul>
    </div>

    <div class="shopping-card">
      <div class="shopping-description-card">
        <div class="image-product-description">
          <div class="image">
            <div class="back-button">
              <router-link
                :to="{
                  name: 'allproducts',
                  params: { id: product_details.category.name },
                }"
                class="go_back_btn"
              >
                <span>Go Back</span>
              </router-link>
            </div>
            <div class="big-image">
              <img :src="product_details.image" alt="product image" />
            </div>
            <div class="small-image-wrap">
              <div class="small-image">
                <img :src="product_details.image" alt="product image" />
              </div>
            </div>
          </div>
          <div class="product-description">
            <h1>{{ product_details.name }}</h1>
            <div class="product-status">
              <p class="category">
                Category:
                <span>{{ product_details.category.name }}</span>
              </p>
              <p class="brand" v-if="product_details.brand">
                Brand:
                <span>{{ product_details.brand.name }}</span>
              </p>
              <p class="brand" v-if="product_details.author">
                Vendor:
                <router-link
                  :to="{
                    name: 'vendor',
                    params: { slug: product_details.author.username },
                  }"
                >
                  <span
                    >{{ product_details.author.first_name }}
                    {{ product_details.author.middle_name }}
                    {{ product_details.author.last_name }}</span
                  >
                </router-link>
              </p>
              <p class="availability">
                Availability:
                <span>In stock</span>
              </p>
            </div>
            <div class="price-quantity">
              <!-- <p class="price">NPR {{product_details.price_out}} / {{ product_details.unit.name }}</p> -->
              <p class="price">
                NPR {{ selected_unit_obj.price }} /
                {{ selected_unit_obj.unit.name }}
              </p>
              <p class="quantity">
                Quantity
                <span class="quantity-inc-dec">
                  <button @click="decreaseQuantity">&laquo;</button>
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    class="quantity-input"
                    v-model="quantity_nunber"
                  />
                  <button @click="increaseQuantity">&raquo;</button>
                </span>
              </p>
            </div>
            <div class="custom-unit-select">
              <strong> Unit: </strong>
              <div class="select-dropdown">
                <select
                  v-model="selected_unit_id"
                  @change="onUnitChange($event)"
                >
                  <option
                    v-if="
                      product_details.product_unit &&
                      product_details.product_unit.length > 0
                    "
                    v-for="(
                      product_unit, index
                    ) in product_details.product_unit"
                    :key="index"
                    :value="product_unit.id"
                  >
                    {{ product_unit.unit.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="proceed-button-wrap">
              <button
                class="add-to-cart"
                @click="addToCartModal(product_details)"
              >
                <span>Add to Basket</span>
              </button>
              <router-link :to="{ name: 'checkoutPage' }" class="buy-now">
                <span>Buy now</span>
              </router-link>
            </div>
          </div>
        </div>

        <div class="product-detail">
          <p>{{ product_details.description }}</p>
        </div>

        <div class="accordion">
          <div class="button" @click="showReview = !showReview">
            <button type="button" class="accordion-button">
              Reviews ({{
                getProductRatingList && getProductRatingList.length > 0
                  ? getProductRatingList.length
                  : 0
              }})
            </button>
            <div class="accordion-close">
              <i class="fa fa-angle-down"></i>
            </div>
          </div>

          <transition name="fade">
            <div class="accordion-content" v-show="showReview">
              <form
                v-if="getSingleUser && getSingleUser.id > 0"
                @submit.stop.prevent="submitReviewForm"
              >
                <label for="subject">Add your review</label>
                <textarea
                  id="subject"
                  placeholder="Your Review..."
                  style="height: 200px"
                  v-model.trim="review.message"
                ></textarea>
                <span
                  class="text-danger float-right"
                  style="color: red"
                  v-if="errors.message"
                  >{{ errors.message }}</span
                >

                <div></div>

                <div class="rating-button">
                  <div class="rate">
                    <fieldset class="rating">
                      <input
                        type="radio"
                        id="star5"
                        name="rating"
                        value="5"
                        @click="getStarValue(5)"
                      />
                      <label
                        class="full"
                        for="star5"
                        title="Awesome - 5 stars"
                      ></label>
                      <input
                        type="radio"
                        id="star4"
                        name="rating"
                        value="4"
                        @click="getStarValue(4)"
                      />
                      <label
                        class="full"
                        for="star4"
                        title="Pretty good - 4 stars"
                      ></label>
                      <input
                        type="radio"
                        id="star3"
                        name="rating"
                        value="3"
                        @click="getStarValue(3)"
                      />
                      <label
                        class="full"
                        for="star3"
                        title="Meh - 3 stars"
                      ></label>
                      <input
                        type="radio"
                        id="star2"
                        name="rating"
                        value="2"
                        @click="getStarValue(2)"
                      />
                      <label
                        class="full"
                        for="star2"
                        title="Kinda bad - 2 stars"
                      ></label>
                      <input
                        type="radio"
                        id="star1"
                        name="rating"
                        value="1"
                        @click="getStarValue(1)"
                      />
                      <label
                        class="full"
                        for="star1"
                        title="Sucks big time - 1 star"
                      ></label>
                      <br clear="all" />
                      <span
                        class="text-danger float-right"
                        style="color: red"
                        v-if="errors.starvalue"
                        >{{ errors.starvalue }}</span
                      >
                    </fieldset>
                  </div>

                  <div class="submit-button">
                    <button type="submit"><span>Submit</span></button>
                  </div>
                </div>
                <div v-if="success">
                  <span style="color: green; font-weight: bold"
                    >Review Submitted Successfully!</span
                  >
                </div>
              </form>

              <div
                v-if="getProductRatingList && getProductRatingList.length > 0"
                class="reviews"
              >
                <span>Reviews</span>

                <!-- Review Item -->
                <div
                  class="user-review"
                  v-for="(rating, index) in getProductRatingList"
                  :key="index"
                >
                  <div class="reviewer-name">
                    {{ rating.author.full_name }}
                    <span>[{{ rating.rating }}/5]</span>
                  </div>
                  <div class="rated">
                    <fieldset class="rating">
                      <label
                        class="full"
                        :class="rating.rating < 5 ? 'grey' : ''"
                        title="Awesome - 5 stars"
                      ></label>

                      <label
                        class="full"
                        :class="rating.rating < 4 ? 'grey' : ''"
                        title="Pretty good - 4 stars"
                      ></label>

                      <label
                        class="full"
                        :class="rating.rating < 3 ? 'grey' : ''"
                        title="Meh - 3 stars"
                      ></label>

                      <label
                        class="full"
                        :class="rating.rating < 2 ? 'grey' : ''"
                        title="Kinda bad - 2 stars"
                      ></label>

                      <label
                        class="full"
                        :class="rating.rating < 1 ? 'grey' : ''"
                        title="Sucks big time - 1 star"
                      ></label>
                    </fieldset>
                  </div>
                  <p v-html="rating.review"></p>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <ModalSuccess
      :tag="cart_success_message"
      v-show="isModalSuccessVisible"
      @close="isModalSuccessVisible = !isModalSuccessVisible"
    />
    <ModalWarning
      :tag="response_message"
      v-show="isModalWarningVisible"
      @close="isModalWarningVisible = !isModalWarningVisible"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ModalSuccess from "../components/ModalSuccess.vue";
import ModalWarning from "../components/ModalWarning.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  computed: {
    ...mapState([
      "product_list",
      "product_rating_obj",
      "success_message",
      "error_message",
      "addtocard_message",
      "response_message",
      "user_profile",
    ]),
    getProductRatingList() {
      let ratingObj = this.product_rating_obj;
      ratingObj = ratingObj.filter((row) => {
        return row.product.id == this.product_id;
      });
      return ratingObj;
    },
    getSingleUser() {
      if (this.user_profile) {
        return this.user_profile;
      } else {
        return null;
      }
    },
  },
  components: {
    Loading,
    ModalSuccess,
    ModalWarning,
  },
  watch: {
    product_list: function () {
      if (this.product_id) {
        this.product_details = this.product_list.find(
          (evt) => evt.id == this.product_id
        );
        this.category_id = this.product_details.category;

        this.selected_unit_obj = this.product_details.product_unit.find(
          (evt) => evt.unit.id == this.product_details.unit.id
        );
        this.selected_unit_id = this.selected_unit_obj.id;
        // this.selected_unit_id = this.product_details.unit.id
        // this.selected_unit_obj = {
        //                       "id": 1111111111,
        //                       "unit": {
        //                           "id": this.product_details.unit.id,
        //                           "name": this.product_details.unit.name
        //                       },
        //                       "product": this.product_details.id,
        //                       "price": this.product_details.price_out
        //                     }
      }
    },
    "$route.params.id"() {
      window.scrollTo(0, 0);

      this.product_details = this.product_list.find(
        (evt) => evt.id == this.$route.params.id
      );
      this.category_id = this.product_details.category;

      this.selected_unit_obj = this.product_details.product_unit.find(
        (evt) => evt.unit.id == this.product_details.unit.id
      );
      this.selected_unit_id = this.selected_unit_obj.id;
      // this.selected_unit_id = 0
      // this.selected_unit_obj = {
      //                       "id": 1111111111,
      //                       "unit": {
      //                           "id": this.product_details.unit.id,
      //                           "name": this.product_details.unit.name
      //                       },
      //                       "product": this.product_details.id,
      //                       "price": this.product_details.price_out
      //                     }
    },
  },

  created() {
    this.listProducts();
    this.listProductRating();
    if (this.$store.state.token != "" || this.$store.state.token != null) {
      this.listProfile();
    }
  },

  methods: {
    ...mapActions(["listProducts", "listProductRating", "listProfile"]),
    addToCartModal(product) {
      if (
        this.selected_unit_id <= 0 ||
        this.selected_unit_id == "" ||
        !this.selected_unit_id
      ) {
        alert("Please select a unit value first.");
      } else {
        let cartItem = {
          id: product.id,
          name: product.name,
          unit: this.selected_unit_obj.unit.id,
          unitname: this.selected_unit_obj.unit.name,
          image: product.image,
          quantity: this.quantity_nunber,
          price: this.selected_unit_obj.price,
          update: false,
        };

        //console.log("CRT: ", JSON.stringify(cartItem));

        this.$store.commit("addUpdateToCart", cartItem);
        if (this.error_message == "errormessage") {
          this.isModalWarningVisible = true;
        } else {
          this.isModalSuccessVisible = true;
        }
      }
    },
    increaseQuantity() {
      this.quantity_nunber++;
    },
    decreaseQuantity() {
      if (this.quantity_nunber === 1) {
        alert("Quantity should be greater than or equal to 1");
      } else {
        this.quantity_nunber--;
      }
    },
    getStarValue(value) {
      this.selectedStartValue = value;
    },
    submitReviewForm(event) {
      this.errors = [];

      if (this.review.message == "") {
        this.errors["message"] = "Message required.";
      } else if (this.selectedStartValue <= 0) {
        this.errors["starvalue"] = "Rating required.";
      } else {
        if (this.getSingleUser && this.getSingleUser.id > 0) {
          let formData = new FormData();
          formData.append("product", this.product_id);
          formData.append("author", this.getSingleUser.id);
          formData.append("rating", this.selectedStartValue);
          formData.append("review", this.review.message);
          formData.append("ip_address", "127.0.0.1");

          // for (var pair of formData.entries()) {
          //   console.log(pair[0]+ ', ' + pair[1]);
          // }

          this.$store.dispatch("createProductRating", formData).then(() => {
            if (this.success_message == "success") {
              this.success = true;
              setTimeout(function () {
                location.reload();
              }, 1000);
            } else if (this.error_message == "errormessage") {
              alert(this.response_message);
            }
          });
        } //if
      }
    },
    getDefaultUnit(unitId) {
      this.selected_unit_obj = this.product_details.product_unit.find(
        (evt) => evt.unit.id == unitId
      );
      //this.selected_unit_id = this.selected_unit_obj.id
    },
    onUnitChange(event) {
      var unitId = event.target.value;
      this.selected_unit_obj = this.product_details.product_unit.find(
        (evt) => evt.id == unitId
      );
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  data() {
    return {
      isLoading: false,
      show: false,
      showReview: false,
      product_id: this.$route.params.id,
      product_details: [],
      product_rating: [],
      single_user: [],
      quantity_nunber: 1,
      isModalWarningVisible: false,
      isModalSuccessVisible: false,
      selectedStartValue: 0,
      review: {
        message: "",
        name: "",
        email: "",
      },
      selected_unit_id: 0,
      selected_unit_obj: "",
      cart_success_message: "Product Successfully Added in Cart.",
      success: false,
      errors: [],
    };
  },
};
</script>

<style lang="scss">
.rated .rating > .grey:before {
  color: #c5c5c5;
}
.product-detail {
  width: 100%;
  float: left;
  margin: 20px 0 0 0;
}
.product-detail p {
  font-size: 15px;
  color: #484848;
  line-height: 24px;
}

.back-button {
  margin-bottom: 20px;
}
.go_back_btn {
  padding: 10px 25px;
  margin: 10px 0px;
  background: #4ba069;
  font-size: 16px;
  font-family: "Roboto";
  // border-radius: 5px;
  span {
    color: #fff;
  }
}

.go_back_btn:hover {
  background: #2a7c47;
}
</style>
