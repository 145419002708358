<template>
  <div
    class="product-detail-modal"
    v-if="tag['category']"
    @click="$emit('close')"
  >
    <div class="modal-content" @click.stop>
      <div class="close-detail" @click="$emit('close')">
        <i class="fas fa-times"></i>
      </div>
      <div class="product-information">
        <div class="modal-image" v-if="tag['image']">
          <img :src="tag['image']" alt="image" />
        </div>
        <div class="product-description">
          <h1>{{ tag["name"] }}</h1>
          <p class="category">
            Category:
            <span>{{ tag["category"]["name"] }}</span>
          </p>
          <p class="availability">
            Availability:
            <span>In stock</span>
          </p>
          <p v-if="selected_unit_obj != ''" class="price">NPR {{ selected_unit_obj.price }} / {{ selected_unit_obj.unit.name }}</p>
          <p v-else class="price">NPR {{ tag["price_out"] }} / {{ tag["unit"]["name"] }}</p>
          <p class="quantity">
            Quantity
            <span class="quantity-inc-dec">
              <button @click="decreaseQuantity">&laquo;</button>
              <input
                type="number"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                class="quantity-input"
                v-model="quantity_nunber"
              />
              <button @click="increaseQuantity">&raquo;</button>
            </span>
          </p>
          <div class="custom-unit-select">
              <strong> Unit: </strong>
            <div class="select-dropdown">
              {{ getDefaultUnitId(tag["unit"]["id"]) }}
              <select v-model="selected_unit_id" @change="onUnitChange($event)">
                <option
                  v-if="tag.product_unit && tag.product_unit.length > 0"
                  v-for="(product_unit, index) in tag.product_unit"
                  :key="index"
                  :value="product_unit.id"
                >{{ product_unit.unit.name }}</option>
              </select>
            </div>
          </div>
          <router-link
            :to="{ name: 'productdetails', params: { id: tag.id } }"
            class="detail"
          >
            View full details
          </router-link>
          <div class="proceed-button-wrap">
            <button class="add-to-cart" @click="addToCartModal(tag)">
              <span>Add to Basket</span>
            </button>
            <!-- <router-link :to="{ name: 'checkoutPage' }" class="buy-now">
              <span>Buy now</span>
            </router-link> -->
          </div>
        </div>
      </div>
    </div>
    <ModalWarning
      :tag="response_message"
      v-show="isModalWarningVisible"
      @close="isModalWarningVisible = !isModalWarningVisible"
    />
    <ModalSuccess
      :tag="cart_success_message"
      v-show="isModalSuccessVisible"
      @close="isModalSuccessVisible = !isModalSuccessVisible"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ModalWarning from "../components/ModalWarning.vue";
import ModalSuccess from "../components/ModalSuccess.vue";

export default {
  name: "ProductPreviewModal",
  components: {
    ModalWarning,
    ModalSuccess,
  },

  props: ["tag"],
  // methods: {
  //   close() {
  //     this.$emit('close');
  //   },
  // },

  computed: {
    ...mapState([
      "product_list",
      "error_message",
      "addtocard_message",
      "response_message",
    ]),
  },

  created() {
    this.listProducts();
  },
  mounted: function() {
    if (this.tag != "") {
    }
  },
  watch: {
    tag: function(){
      this.selected_unit_obj = this.tag.product_unit.find(
        evt => evt.unit.id == this.tag["unit"]["id"]
      );
      this.selected_unit_id = this.selected_unit_obj.id
    }
  },
  data() {
    return {
      single_product_data: "",
      quantity_nunber: 1,
      isModalWarningVisible: false,
      isModalSuccessVisible: false,
      cart_success_message: "Product Successfully Added in Cart.",
      selected_unit_id: 0,
      selected_unit_obj: '',
      defaultUnitObj: '',
    };
  },

  methods: {
    ...mapActions(["listProducts"]),
    getSingleProduct(product_id) {
      this.single_product_data = this.product_list.find(
        (evt) => evt.id == product_id
      );
    },
    addToCartModal(product) {
      if(this.selected_unit_id <= 0 || this.selected_unit_id == '' || !this.selected_unit_id){
        alert("Please select a unit value first.")
      }else{
        let cartItem = {
          id: product.id,
          name: product.name,
          unit: this.selected_unit_obj.unit.id,
          unitname: this.selected_unit_obj.unit.name,
          image: product.image,
          quantity: this.quantity_nunber,
          price: this.selected_unit_obj.price,
          update: false,
        };
        
        this.$store.commit("addUpdateToCart", cartItem);
        if (this.error_message == "errormessage") {
          this.isModalWarningVisible = true;
        } else {
          this.isModalSuccessVisible = true;
        }
      }
    },
    increaseQuantity() {
      this.quantity_nunber++;
    },
    decreaseQuantity() {
      if (this.quantity_nunber === 1) {
        alert("Quantity should be greater than or equal to 1");
      } else {
        this.quantity_nunber--;
      }
    },
    getDefaultUnitId(unitId){
      // this.selected_unit_obj = this.tag.product_unit.find(
      //   evt => evt.unit.id == unitId
      // );
      // this.selected_unit_id = this.selected_unit_obj.id
    },
    onUnitChange(event) {
      var unitId = event.target.value
      this.selected_unit_obj = this.tag.product_unit.find(
        evt => evt.id == unitId
      );
      this.selected_unit_id = this.selected_unit_obj.id
    },
  },
};
</script>

<style lang="scss">
.quantity-inc-dec button,
.quantity-inc-dec input{
  padding: 8px 10px;
}
.custom-unit-select {
  margin: 15px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  strong {
    padding-right: 20px;
  }
  // select {
  //   border: 1px solid grey;
  //   padding: 5px 0;
  // }
  .select-dropdown,
  .select-dropdown * {
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
  }
  .select-dropdown {
    position: relative;
    background-color: #e6e6e6;
    border-radius: 4px;
  }
  .select-dropdown select {
    font-size: 1rem;
    font-weight: normal;
    max-width: 100%;
    padding: 8px 24px 8px 10px;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .select-dropdown select:active,
  .select-dropdown select:focus {
    outline: none;
    box-shadow: none;
  }
  .select-dropdown:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    width: 0;
    height: 0;
    margin-top: -2px;
    border-top: 5px solid #aaa;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
}
</style>
