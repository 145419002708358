export default {
  title: "** GreenGrowth **",
  token: window.localStorage.getItem("token"),
  categories_list: [],
  product_list: [],
  userList: [],
  banner_list: [],
  vendor_product_list: [],
  success_message: [],
  error_message: [],
  response_message: [],
  cardproduct_obj: [],
  notification_obj: [],
  addtocard_message: [],
  user_register_message: [],
  user_profile: [],
  search_product: [],
  delivery_address: [],
  cart_product: [],
  product_rating_obj: [],
  static_page_obj: [],
  billing_address_obj: [],
  cartCount: 0,
  batch_list: [],
};
