<template>
  <div class="purchase-card">
    <!-- <div class="gg-container"> -->
    <div
      v-if="product_in_cart && product_in_cart.length > 0"
      class="product-purchase-card"
    >
      <div class="purchase-card-heading">
        <div class="cart-items-title">product</div>
        <div class="cart-items-title">price</div>
        <div class="cart-items-title">quantity</div>
        <!-- <div class="cart-items-title">Unit</div> -->
        <div class="cart-items-title">total</div>
      </div>
      <!-- <div class="status-message success-message">
          <span class="status-icon">
            <i class="far fa-check-circle"></i>
          </span>
          Successfully Added.
        </div> -->
      <div
        class="status-message no-item-message"
        v-if="product_in_cart.length < 1 && token != ''"
      >
        <span class="status-icon">
          <i class="fas fa-exclamation"></i>
        </span>
        Sorry. No item currently.
      </div>
      <!-- <div class="status-message error-message" v-if="token==''">
          <span class="status-icon">
            <i class="fas fa-times"></i>
          </span>
          Sorry. You have to login to see your Card Product List.
        </div> -->
      <div
        class="purchase-card-detail"
        v-for="(product_obj, index) in product_in_cart"
        :key="index"
      >
        <button
          class="delete-button"
          @click="removeProductFromCart(product_obj)"
        >
          <!-- <i class="far fa-trash-alt"></i> -->
          <i class="fas fa-trash"></i> Remove
        </button>
        <div class="purchase-item-image">
          <div class="cart-product-image">
            <img :src="product_obj.image" class="product-image" alt="image" />
          </div>
          <div class="purchase-item-name">{{ product_obj.name }}</div>
        </div>
        <div class="purchase-price">
          <span
            >NPR.{{ product_obj.price.toFixed(2) }} /
            {{ product_obj.unitname }}</span
          >
        </div>
        <div class="purchase-pagination">
          <span class="quantity-inc-dec">
            <button @click="decreaseQuantity(product_obj)">&laquo;</button>
            <input
              type="number"
              class="quantity-input"
              readonly
              :value="product_obj.quantity"
            />
            <button @click="increaseQuantity(product_obj)">&raquo;</button>
          </span>
        </div>
        <!-- <div class="select-unit-cart">
          <select>
            <option>Kg</option>
            <option>Gram</option>
            <option>Chhatak</option>
          </select>
        </div> -->
        <div class="purchase-total">
          <span
            >NPR.
            {{ (product_obj.price * product_obj.quantity).toFixed(2) }}</span
          >
        </div>
      </div>
      <div class="purchase-button">
        <div v-if="product_in_cart && product_in_cart.length > 0">
          <button class="check-out-button" @click="checkoutNow">
            <span>Checkout</span>
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="purchase-card-heading">
        <h2 style="text-align: center;">Your cart is empty !</h2>
      </div>
    </div>
    <!-- </div> -->
    <ModalWarning
      :tag="tag_message"
      v-show="isModalWarningVisible"
      @close="isModalWarningVisible = !isModalWarningVisible"
    />
    <LoginForm
      v-if="showLoginModal"
      @close="showLoginModal = false"
      @open="(showRegistrationForm = true)((showLoginModal = false))"
    />
    <RegistrationForm
      v-if="showRegistrationForm"
      @open="(showRegistrationForm = false)((showLoginModal = true))"
      @close="showRegistrationForm = false"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ModalWarning from "../components/ModalWarning.vue";
import LoginForm from "../components/LoginForm.vue";
import RegistrationForm from "./RegistrationForm.vue";

export default {
  name: "ProductOnCart",
  components: {
    ModalWarning,
    LoginForm,
    RegistrationForm
  },
  computed: {
    ...mapState(["token", "success_message", "error_message"]),
  },
  created() {
    //
  },
  data() {
    return {
      quantity_nunber: 1,
      single_product: [],
      product_in_cart: JSON.parse(localStorage.getItem("localCartData")),
      showLoginModal: false,
      showRegistrationForm: false,
      isModalWarningVisible: false,
      tag_message: "",
    };
  },
  methods: {
    ...mapActions(["listCartProducts"]),
    increaseQuantity(product_obj) {
      this.$store.commit("incrementFromCart", product_obj);
      setTimeout(function() {
        location.reload();
      }, 100);
    },
    decreaseQuantity(product_obj) {
      this.$store.commit("decrementFromCart", product_obj);
      setTimeout(function() {
        location.reload();
      }, 100);
    },
    removeProductFromCart(cart_obj) {
      this.$store.commit("removeFromCart", cart_obj);
      if (this.success_message == "success") {
        setTimeout(function() {
          location.reload();
        }, 500);
      }
    },
    checkoutNow() {
      if (
        this.$store.state.token == "" ||
        this.$store.state.token == null ||
        this.product_in_cart.length <= 0
      ) {
        this.tag_message = "Please LOGIN to continue on your checkout process.";
        //this.isModalWarningVisible = true;
        this.showLoginModal = true;
      } else {
        this.$router.push({
          name: "checkoutPage",
        });
      }
    },
  },
};
</script>

<style>
.select-unit-cart select {
  padding: 6px 0;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
}
</style>
