<template>
  <div class="gg-container">
    <ContinueButton/>
    <BillingInformation/>
  </div>
</template>

<script>
import ContinueButton from '../components/ContinueButton.vue';
import BillingInformation from '../components/BillingInformation.vue';

export default {
  name: 'BillingPage',
  components: {
    ContinueButton,
    BillingInformation,
  },
};
</script>

<style>

</style>
