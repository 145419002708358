export const province =[
  // Province 1
  // {
  //   name:"Province 1",
  //   districts: [
  //     "Bhojpur",
  //     "Dhankuta",
  //     "Ilam",
  //     "Jhapa",
  //     "Khotang",
  //     "Morang",
  //     "Okhaldhunga",
  //     "Panchthar",
  //     "Sankhuwasabha",
  //     "Solukhumbu",
  //     "Sunsari",
  //     "Taplejung",
  //     "Terhathum",
  //     "Udayapur"
  //   ]
  // },

  // Province 2
  // {
  //   name: "Province 2",
  //   districts:[
  //     "Saptari",
  //     "Siraha",
  //     "Dhanusa",
  //     "Mahottari",
  //     "Sarlahi",
  //     "Bara",
  //     "Parsa",
  //     "Rautahat"
  //   ]
  // },

  // Province 3
  // {
  //   name: "Bagmati Pradesh",
  //   districts:[
  //     "Sindhuli",
  //     "Ramechhap",
  //     "Dolakha",
  //     "Bhaktapur",
  //     "Dhading",
  //     "Kathmandu",
  //     "Kavrepalanchok",
  //     "Lalitpur",
  //     "Nuwakot",
  //     "Rasuwa",
  //     "Sindhupalchok",
  //     "Chitwan",
  //     "Makwanpur"
  //   ]
  // },

  {
    name: "Bagmati Pradesh",
    districts:[
      "Kathmandu",
      "Lalitpur",
      "Bhaktapur",
    ]
  },

  // Gandaki Pradesh
  // {
  //   name: "Gandaki Pradesh",
  //   districts:[
  //     "Baglung",
  //     "Gorkha",
  //     "Kaski",
  //     "Lamjung",
  //     "Manang",
  //     "Mustang",
  //     "Myagdi",
  //     "Nawalpur",
  //     "Parbat",
  //     "Syangja",
  //     "Tanahun"
  //   ]
  // },

  //Province 5
  // {
  //   name: "Province 5",
  //   districts:[
  //     "Kapilvastu",
  //     "Parasi",
  //     "Rupandehi",
  //     "Arghakhanchi",
  //     "Gulmi",
  //     "Palpa",
  //     "Dang",
  //     "Pyuthan",
  //     "Rolpa",
  //     "Purbi Rukum",
  //     "Banke",
  //     "Bardiya"
  //   ]
  // },

  //Karnali Pradesh
  // {
  //   name: "Karnali Pradesh",
  //   districts:[
  //     "Paschimi Rukum",
  //     "Salyan",
  //     "Dolpa",
  //     "Humla",
  //     "Jumla",
  //     "Kalikot",
  //     "Mugu",
  //     "Surkhet",
  //     "Dailekh",
  //     "Jajarkot"
  //   ]
  // },

  // Sudurpashchim Pradesh
  // {
  //   name: "Sudurpashchim Pradesh",
  //   districts:[
  //     "Kailali",
  //     "Achham",
  //     "Doti",
  //     "Bajhang",
  //     "Bajura",
  //     "Kanchanpur",
  //     "Dadeldhura",
  //     "Baitadi",
  //     "Darchula"
  //   ]
  // }
];

export default province;
