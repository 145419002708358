<template>
  <div class="error-page">
      <div class="main-content">
        <div class="icon">
          <span>
            <i class="fas fa-times-circle"></i>
          </span>
        </div>
        <p class="message">
          Oops! We got an error.
        </p>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>