export default {
  setToken(state, token) {
    state.token = token;
  },

  setUserList(state, userList) {
    state.userList = userList;
  },

  setCategoriesList(state, categories_list) {
    state.categories_list = categories_list;
  },

  setProductList(state, product_list) {
    state.product_list = product_list;
  },

  setBannerList(state, banner_list) {
    state.banner_list = banner_list;
  },

  setBatchList(state, batch_list) {
    state.batch_list = batch_list;
  },

  setVendorProductList(state, vendor_product_list) {
    state.vendor_product_list = vendor_product_list;
  },

  setNotificationList(state, notification_obj) {
    state.notification_obj = notification_obj;
  },

  setSuccessMessage(state, success_message) {
    state.success_message = success_message;
  },

  setErrorMessage(state, error_message) {
    state.error_message = error_message;
  },

  setMessage(state, response_message) {
    state.response_message = response_message;
  },

  setCartProductList(state, cardproduct_obj) {
    state.cardproduct_obj = cardproduct_obj;
  },

  setCartSuccessMessage(state, addtocard_message) {
    state.addtocard_message = addtocard_message;
  },

  restoreCart(state) {
    state.cartCount = 0;
  },

  addUpdateToCart(state, cart_item) {
    let localStorageCartData = JSON.parse(
      localStorage.getItem("localCartData")
    );
    if (localStorageCartData.length > 0) {
      state.cartCount = localStorageCartData.length;
      for (var i = 0; i < localStorageCartData.length; i++) {
        let createNewCartItem = {
          id: localStorageCartData[i].id,
          name: localStorageCartData[i].name,
          unit: localStorageCartData[i].unit,
          unitname: localStorageCartData[i].unitname,
          image: localStorageCartData[i].image,
          quantity: localStorageCartData[i].quantity,
          price: localStorageCartData[i].price,
          update: localStorageCartData[i].update,
        };
        // Check weather the item in localstorage or not and push it to state.cart_product
        let checkInState = state.cart_product.find(
          (x) => x.id == localStorageCartData[i].id
        );
        if (!checkInState) {
          state.cart_product.push(createNewCartItem);
        }
      }
    }

    let found = localStorageCartData.find((x) => x.id == cart_item.id);

    if (found && found.id !== "") {
      if (found.update) {
        state.error_message = "noerror";
        // Update on Cart
        let foundQty = parseFloat(found.quantity);
        let foundPrice = parseFloat(found.price);
        let newcartItem = {
          id: found.id,
          name: found.name,
          unit: found.unit,
          unitname: found.unitname,
          image: found.image,
          quantity: foundQty++,
          price: foundPrice,
          update: found.update,
        };

        //Remove this item from state
        localStorage.removeItem("localCartData");
        let index_2 = state.cart_product.indexOf(found);
        if (index_2 > -1) {
          state.cart_product.splice(index_2, 1);
        }

        newcartItem.quantity = foundQty;
        newcartItem.totalPrice = foundQty * foundPrice;

        // Again Push the item in cart
        state.cart_product.push(newcartItem);
      } else {
        state.response_message = "Product already in Cart.";
        state.error_message = "errormessage";
      }
    } else {
      state.error_message = "noerror";
      // Add on Cart
      state.cart_product.push(cart_item);
      cart_item.quantity = cart_item.quantity <= 0 ? 1 : cart_item.quantity;
      cart_item.totalPrice = cart_item.price;
      state.cartCount++;
    }

    // add finalcartdata in Storage
    localStorage.setItem("localCartData", JSON.stringify(state.cart_product));
  },

  removeFromCart(state, cart_item) {
    let localStorageCartData = JSON.parse(
      localStorage.getItem("localCartData")
    );
    if (localStorageCartData.length > 0) {
      state.cart_product = [];
      for (var i = 0; i < localStorageCartData.length; i++) {
        let createNewCartItem = {
          id: localStorageCartData[i].id,
          name: localStorageCartData[i].name,
          unit: localStorageCartData[i].unit,
          unitname: localStorageCartData[i].unitname,
          image: localStorageCartData[i].image,
          quantity: localStorageCartData[i].quantity,
          price: localStorageCartData[i].price,
        };
        // Check weather the item in localstorage or not and push it to state.cart_product
        let checkInState = state.cart_product.find(
          (x) => x.id == localStorageCartData[i].id
        );
        if (!checkInState) {
          state.cart_product.push(createNewCartItem);
        }
      }
    }

    let found = state.cart_product.find((x) => x.id == cart_item.id);

    if (found) {
      localStorage.removeItem("localCartData");
      //Remove this item from state
      let index_2 = state.cart_product.indexOf(found);
      if (index_2 > -1) {
        state.cart_product.splice(index_2, 1);
      }
      state.success_message = "success";
    } else {
      state.error_message = "errormessage";
    }

    localStorage.setItem("localCartData", JSON.stringify(state.cart_product));

    // let localStorageCartData = JSON.parse(localStorage.getItem('localCartData'))
    // let found = localStorageCartData.find(x => x.id == item.id)

    // //Remove this item from state
    // let index_1 = localStorageCartData.indexOf(found);
    // localStorageCartData.splice(index_1);
    // // let index_2 = state.cart_product.indexOf(found);
    // // state.cart_product.splice(index_2, 1);

    // console.log("AAA", index_1)
    // //console.log("BBB", index_2)

    //state.success_message = "success"
  },

  incrementFromCart(state, cart_item) {
    let localStorageCartData = JSON.parse(
      localStorage.getItem("localCartData")
    );
    if (localStorageCartData.length > 0) {
      state.cart_product = [];
      for (var i = 0; i < localStorageCartData.length; i++) {
        let newQuantity = localStorageCartData[i].quantity;
        if (localStorageCartData[i].id === cart_item.id) {
          newQuantity = parseFloat(localStorageCartData[i].quantity) + 1;
        }
        let createNewCartItem = {
          id: localStorageCartData[i].id,
          name: localStorageCartData[i].name,
          unit: localStorageCartData[i].unit,
          unitname: localStorageCartData[i].unitname,
          image: localStorageCartData[i].image,
          quantity: newQuantity,
          price: localStorageCartData[i].price,
        };
        // Check weather the item in localstorage or not and push it to state.cart_product
        let checkInState = state.cart_product.find(
          (x) => x.id == localStorageCartData[i].id
        );
        if (!checkInState) {
          state.cart_product.push(createNewCartItem);
        }
      }
      localStorage.removeItem("localCartData");
      localStorage.setItem("localCartData", JSON.stringify(state.cart_product));
    }
  },

  decrementFromCart(state, cart_item) {
    let localStorageCartData = JSON.parse(
      localStorage.getItem("localCartData")
    );
    if (localStorageCartData.length > 0) {
      state.cart_product = [];
      for (var i = 0; i < localStorageCartData.length; i++) {
        let newQuantity = localStorageCartData[i].quantity;
        if (localStorageCartData[i].id === cart_item.id) {
          newQuantity =
            newQuantity > 1
              ? parseFloat(localStorageCartData[i].quantity) - 1
              : 1;
        }
        let createNewCartItem = {
          id: localStorageCartData[i].id,
          name: localStorageCartData[i].name,
          unit: localStorageCartData[i].unit,
          unitname: localStorageCartData[i].unitname,
          image: localStorageCartData[i].image,
          quantity: newQuantity,
          price: localStorageCartData[i].price,
        };
        // Check weather the item in localstorage or not and push it to state.cart_product
        let checkInState = state.cart_product.find(
          (x) => x.id == localStorageCartData[i].id
        );
        if (!checkInState) {
          state.cart_product.push(createNewCartItem);
        }
      }
      localStorage.removeItem("localCartData");
      localStorage.setItem("localCartData", JSON.stringify(state.cart_product));
    }
  },

  setUserRegisterMessage(state, user_register_message) {
    state.user_register_message = user_register_message;
  },

  setUserprofile(state, user_profile) {
    state.user_profile = user_profile;
  },

  setSearchProductList(state, search_product) {
    state.search_product = search_product;
  },

  setDeliveryAddressList(state, delivery_address) {
    state.delivery_address = delivery_address;
  },

  setProductRatingList(state, product_rating_obj) {
    state.product_rating_obj = product_rating_obj;
  },

  setStaticPageList(state, static_page_obj) {
    state.static_page_obj = static_page_obj;
  },

  setBillingAddressList(state, billing_address_obj) {
    state.billing_address_obj = billing_address_obj;
  },
};
