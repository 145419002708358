export default {
  data() {
    return {
      // 'auth': true
      // showProductPreviewModal: true,
      isModalVisible: false,
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
};
