<template>
  <div class="contact-page gg-container">
    <h2>Contact Us</h2>
    <div class="gg-contact">
      <address>
        <p><strong>Green Growth Group Pvt, Ltd</strong></p>
        <p>Gairigaun, Kathmandu, 014112128</p>
        <a href="mailto:greengrowth00@gmail.com">greengrowth00@gmail.com</a>
      </address>
      <a href="www.greengrowth.life">www.greengrowth.life</a>
    </div>
    <h2>Green Growth Address Map</h2>
    <div class="gg-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.8441955769454!2d85.34979855111023!3d27.691209982714124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198905a00a3b%3A0x38667341258a4a35!2sGreen%20Growth!5e0!3m2!1sen!2snp!4v1594697928894!5m2!1sen!2snp"
        frameborder="0"
        style="border:0;"
        allowfullscreen
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactPage"
};
</script>

<style>
</style>
