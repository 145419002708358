<!-- ===================== -->
<!-- This page is not used -->
<!-- ===================== -->
<template>
  <div>
    <section v-if="search_product.length > 0" class="home-categories-show-case">
      <div class="gg-container">
        <div class="section-heading">
          <h2>Search Results</h2>
        </div>
        <div class="card-wrapper">
          <div
            class="product-card"
            v-for="(search_product_obj,index) in search_product.slice(0,4)"
            :key="index"
          >
            <div class="product-image" id="product-details">
              <img :src="search_product_obj.image" alt="image" />
              <div class="preview-product-icons">
                <button class="preview-icon" @click="isPreviewModal(search_product_obj.id)">
                  <i class="far fa-eye"></i>
                </button>
                <button class="add-to-cart-icon" @click="isCartModal(search_product_obj.id)">
                  <i class="fas fa-shopping-bag"></i>
                </button>
              </div>
            </div>
            <div class="description-card">
              <router-link
                :to="'/product-details/'+search_product_obj.id"
                class="product-description"
                href="#"
              >
                <h1>{{search_product_obj.name}}</h1>
              </router-link>
              <div class="product-quantity">
                <p>Rs {{search_product_obj.price_out}}</p>
                <span>{{search_product_obj.quantity}} {{search_product_obj.unit.name}}</span>
              </div>
            </div>
          </div>
          <!-- <ProductPreviewModal
            :tag="single_product"
            v-show="!isPreviewModalVisible"
            @close="isPreviewModalVisible = !isPreviewModalVisible"
          />
          <AddToCartModal
            :tag_cart="single_product_cart"
            v-show="!isCartModalVisible"
            @close="isCartModalVisible = !isCartModalVisible"
          /> -->
        </div>

        <!-- <ModalWarning
          :tag="response_message"
          v-show="isModalWarningVisible"
          @close="isModalWarningVisible = !isModalWarningVisible"
        /> -->
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
// import ProductPreviewModal from "../componoents/ProductPreviewModal.vue";
// import AddToCartModal from "../components/AddToCartModal.vue";
// import ModalWarning from "../components/ModalWarning.vue";

export default {
  name: "SearchResults",
  components: {
    // ProductCard,
    // ProductPreviewModal,
    // AddToCartModal,
    // ModalWarning,
  },
  data() {
    return {
      isPreviewModalVisible: true,
      isCartModalVisible: true,
      single_product: [],
      single_product_cart: [],
      isModalWarningVisible: false,
    };
  },
  computed: {
    ...mapState([
      "categories_list",
      "product_list",
      "addtocard_message",
      "error_message",
      "search_product",
      "response_message",
    ]),
  },
  watch: {
    search_product: function () {
      if (this.search_product.length > 0) {
        this.setProductList(this.search_product);
      }
    },
  },
  created() {
    this.listCategories();
    this.listProducts();
    this.searchProducts();
  },
  methods: {
    ...mapActions(["listCategories", "listProducts", "searchProducts"]),
    ...mapMutations(["setProductList"]),

    isPreviewModal(product_id) {
      this.single_product = this.product_list.find(
        (evt) => evt.id == product_id
      );

      this.isPreviewModalVisible = false;
    },
    isCartModal(product_id) {
      this.single_product_cart = this.product_list.find(
        (evt) => evt.id == product_id
      );
      this.$store
        .dispatch("addToCart", {
          quantity: 1,
          product: product_id,
        })
        .then(() => {
          if (this.addtocard_message == "success") {
            this.isCartModalVisible = false;
            location.reload();
          } else if (this.error_message == "errormessage") {
            this.isModalWarningVisible = true;
          }
        });
    },
  },
};
</script>

<style>
</style>