<template>
  <div class="product-details-page">
    <ProductCardDetails/>
    <SuggestedProducts/>
  </div>
</template>

<script>
import ProductCardDetails from '../components/ProductCardDetails.vue';
import SuggestedProducts from '../components/SuggestedProducts.vue';

export default {
  name: 'ProductDetails',
  components: {
    ProductCardDetails,
    SuggestedProducts,
  },
};
</script>

<style>
.quantity-inc-dec button,
.quantity-inc-dec input{
  padding: 8px 10px;
}
</style>
