<template>
  <div class="static-page gg-container">
    <!-- <div class="image-section">
      <img src="../assets/images/about-us.png" alt="">
    </div> -->
    <article v-if="static_page_obj">
      <!-- content is placed here -->
      <div v-html="static_page_obj.content"></div>
    </article>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "staticPage",
  data(){
      return{
          page_slug: this.$route.params.slug,
      }
  },
  computed: {
    ...mapState([
      "static_page_obj",
      "error_message",
      "response_message",
    ]),
  },
  created() {
    this.listStaticPageData(this.page_slug);
  },
  methods: {
      ...mapActions(["listStaticPageData"]),
  },
  watch: {
    '$route.params.slug'() {
      this.listStaticPageData(this.$route.params.slug);
    },
  },
};
</script>

<style>
.static-page p, .help-page p, .contact-page p{
  font-size: 18px;
  line-height: 30px;
}

</style>
