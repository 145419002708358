<template>
  <div class="about-page gg-container">
    <article>
      <h2>Green Growth Group</h2>
      <p>Green Growth has a unique story of how it started. It was incubated inside the Story Cycle which is a multimedia and map-based storytelling organization.</p>
      <p>
        Back in 2014, while the Story Cycle was traveling to Jumla district with Story Camp which is a workshop for people demonstrating the ability of digital storytelling to bring out unheard voices of a community, we got good feedback, which we were also asked to prove demonstrated tools in the real world. We accepted it and they sent us a ton of Jumla walnuts and Marsi rice which we sold within a couple of months using our social media network. It was the exact beginning of the concept of Green Growth and a sustainable urban food system. Initially, we continued promoting it because we enjoyed the sense of feeling that we are practicing something that represents an interdependent nation. Then the Earthquake happened, StoryCycle and its team were fully engaged in relief. On the way or relief work, we landed in Kavre district which had a farm called Patale gaun Organic farm. The produces were so good but the vacuum created because of Earthquake and blockade remained for a longer period, the farm already had its clients [outlets] but they were having a hard time, so they dropped their product at the workstation and since we were selling few local products already, so vegetable was also included on our promoting list. As slowly green growth gained momentum inside Kathmandu, a variety of products were added, we realized how important the food system is in our lives. Many of alike businesses evolved as Green Growth systematized which also has helped to promote safe food culture among the urban consumer.
        In October of 2016, Green Growth was registered legally with core team members Saurav Dhakal, Deepika Gyawali, Nischal Pokharel, and Samita Kapali with the objective of linking the producers to the consumers directly by using new media technology along with less environmental degradation.
      </p>
      <p>
        In March of 2019, Seed investors funded Green growth to broaden its market and systematize.
        As Green Growth developed, it also broadened its vision from a sustainable urban food system to promote the circular economy and develop the products accordingly and it has been working with different organic and local producers from Kavre, Sindhuli, Khotang, Saptari, Humla, Jumla, Mustang, Nuwakot, Biratnagar, Mahottari, Dolpa, etc
      </p>
      <p>The Green Growth Group is working on promoting sustainable living. The idea is ‘link the producers to the consumers directly by using new media technology along with less environmental degradation.</p>

      <h3>
        Product One: Weekly Bucket 
      </h3>
      <p>
        We are using social media as a weapon to channelize the products from Farm to Home.  With the vision of establishing sustainable food ecosystem throughout the country #GreenGrowth as a social media platform and http://greengrowth.life  as its official website takes orders from the consumers to compile them and place the orders to the producers so that they can send us their produces which we package and deliver to the respective consumers as a Weekly Bucket. It serves on every Mondays, Wednesdays, and Saturdays in a week.
      </p>
      <h3>
        Product Two: GreenGrowth Travel 
      </h3>
      <p>
        When we think of the joys that come with the adventures of traveling, we often envision exciting trips to beautiful places fit for a perfect photograph, unique foods that we won’t find at home, and encounters with people who are much different from those we are accustomed to. People who seek wilderness and natural experience of living remotely at isolated places to enjoy ecotourism, something that would not be experienced in urban areas. 
      </p>
      <p>
        Designed for those people who want to learn and experience how their food is procured, how farming is like, or learning about ingenious solutions are often quite interesting and Green Growth enables the vision of how successful man can be when he works with nature. 
      </p>
      <p>
        We have helped Patale Gaun organic farm (one of the partner farms of Green Growth) at Kavre build the farm stay facility and promoted a one-night stay package. We have succeeded to take more than 500 tourists (domestic/international) groups from 2016 till now. Misuse and overuse of chemical fertilizers and pesticides contributed heavily to the degradation of many farms and waterways. a sustainable food system is a system that sustains people as well as the land.
      </p>

      <h3>
        Product Three: Green Growth Garden
      </h3>
      <p>
        We are helping urban and suburban farmers for gardening, constructing farm, designing garden and landscape planning. We have a group of experts who are providing consulting services and tips and techniques for sustainable farming, which we have utilized to develop greenery at Nepal Art Village, Sindhuli Agro Company. Ideally, Green Growth garden is more focussed to support it’s partner farm to adopt sustainable farming and climate-smart agricultural techniques, sustainable farming.
      </p>
      <h3>
        Product Four: Green Growth  Market
      </h3>
      <p>
        We have now outlets where one can get selective products of Green Growth. They are Kathmandu Haat, Bhanimandal | Ageno, Shankhamul | Ageno, Manbhawan | Ageno, Anamnagar, Green Growth, Tinkune. Have at least. We are targeting to have at least 10 outlets inside Kathmandu within the year 2022.
      </p>
      <h3>
        Partnered Farms
      </h3>
      <p>
        <ul>
          <li>
            From Shinja Farm, Jumla we delivered walnuts, lentils, Marshi Rice, Apple Brandy, Dried apples, Organic teas, herbs like jimbu etc to the consumer.
          </li>
          <li>
            From Sindhuli Farm we delivered Fruits like lychees, Junar, Mangoes, Jackfruits etc to the consumer.
          </li>
          From Patale Gaun Farm, Kavre we delivered different organic vegetables and local organic products to the consumer.
          <li>
          From Gaun Ghar Organic Farm, Khopasi we delivered different fruits like Kiwi, Strawberries, Oranges etc.
          </li>
          <li>
            From Laxmaniya Farm, Mahottari we delivered different lentils, rice variety, mangoes, pickles etc.
          </li>
          <li>
            From Manglung Farm, Ilam we delivered Green teas and Ghee butter etc.
          </li>
          <li>
            From Manang, Mustang for apple
          </li>
          <li>
            From Amare Nuwakot, Shiitake
          </li>
          <li>
            From Biratnagar Pulses and Litchis
          </li>
          <li>
            From Kakani strawberries 
          </li>
          <li>
            From Rosa Farming concept, Dolpa Saffron, walnuts
          </li>
          <li>
            From Myagdi, Timur and Lasun Chop
          </li>
          <li>
            From Dang, Garlic cloves
          </li>
          <li>
            From Ashapuri: Sitake, avocado, chia seed
          </li>
          <li>
            From R and D Solution: Beans
          </li>
          <li>
            From Khotang, Black lentil
          </li>
          <li>
            From Shreepur, Saptari: Mangoes
          </li>
          <li>
            From Dhye, Musang: Dewa Apples
          </li>
          <li>
            From Saugat Sindhuli: Lentils, Flour and homemade products
          </li>
        </ul>
      </p>
    </article>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>

<style>
.about-page p,
.help-page p,
.contact-page p,
.about-page ul li,
.help-page ul li,
.contact-page ul li {
  font-size: 18px;
  line-height: 30px;
  color: #6f6f6f;
  font-weight: 600;
}
</style>
