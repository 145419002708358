<template>
  <div class="contact-page gg-container notification-page">
    <div class="header-notification">
      <h2 class="dropdown-title">Notifications</h2>
      <button class="button button-outline" @click="markAllSeenNotification()">Mark all Read</button>
    </div>
    
    <div v-if="filterNotification && filterNotification.length > 0">
      <div
        v-for="(notification, index) in filterNotification"
        :key="index"
        class="gg-contact notification_list"
      >
        <address @click="seenThisMessage(notification.id)">
          <h2
            class="notification_title"
            :class="notification.seen ? 'seen' : 'unseen'"
          >
            {{ notification.title }} <span style="font-size: 16px;">on Date: {{ notification.created_at }}</span>
          </h2>
          <p>{{ notification.description }}</p>
        </address>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "notificationPage",
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapState([
      "notification_obj",
      "user_profile",
      "success_message",
      "error_message",
      "response_message",
    ]),
    filterNotification() {
      let allNotifications = [];

      if (this.user_profile) {
        allNotifications = this.notification_obj;
        allNotifications = allNotifications.filter((row) => {
          return row.notification_to.id == this.user_profile.id;
        });
      }

      return allNotifications;
    },
  },
  created() {
    if (this.$store.state.token != "" || this.$store.state.token != null) {
      this.listProfile();
      this.listNotifications();
    }
  },
  methods: {
    ...mapActions(["listNotifications", "user_profile", "listProfile"]),
    seenThisMessage(notificationId) {
      this.$store.dispatch("seenThisNotification", notificationId).then(() => {
        if (this.success_message == "success") {
          location.reload();
        }
      });
    },
    markAllSeenNotification(){
      this.$store.dispatch("seenAllNotificationOfUser").then(() => {
        if (this.success_message == "success") {
          location.reload();
        }
      });
    },
  },
};
</script>
<style type="text/css">
.notification_list {
  cursor: pointer;
}
.notification_title {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 0;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  line-height: 32px;
}
.notification_title.unseen {
  font-weight: bold;
  color: #326946;
}
</style>
<style lang="scss">
.notification-page {
  .gg-contact {
    margin-bottom: 20px;
  }
}
</style>
