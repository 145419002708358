<template>
  <div class="user-avatar" v-if="token">
    
    <!-- <span class="avatar-name">
      Anthonie
    </span>-->
    <div class="avatar"  @click="click" >
      <div class="user-avatar-image">
        <img v-if="user_profile.image == apiBaseUrl+'media/profile/default.jpg'" src="../assets/images/avatar.jpg" />
        <img v-else :src="user_profile.image" />
      </div>
      <span class="user-name">{{ user_profile.full_name }}</span>
      <span class="avatar-arrow-icon">
        <i class="fas fa-angle-down"></i>
      </span>
    </div>
    <div class="avatar-dropdown" v-on-clickaway="away" v-if="showDropDown">
      <span class="dropdown-tooltip">
        <i class="fas fa-sort-up"></i>
      </span>
      <ul>
        <!-- <li>
          <router-link to="/">
            <span @click="showDropDown = false">
              Account
            </span>
          </router-link>
        </li>-->
        <li>
          <a :href="adminPanelUrl+'validate-user?status=validting&date='+currentDate+'&value='+token+'&page=homepage'">
            <span>My Dashboard</span>
          </a>
          <!-- <router-link to="/">
            <span @click="showDropDown = false">
              My Dashboard
            </span>
          </router-link>-->
        </li>
        <li>
          <a :href="adminPanelUrl+'validate-user?status=validting&date='+currentDate+'&value='+token+'&page=profile'">
            <span>My Profile</span>
          </a>
          <!-- <router-link to="/">
            <span @click="showDropDown = false">
              My Profile
            </span>
          </router-link>-->
        </li>
        <li>
          <a :href="adminPanelUrl+'validate-user?status=validting&date='+currentDate+'&value='+token+'&page=orders'">
            <span>My Orders</span>
          </a>
          <!-- <router-link to="/">
            <span @click="showDropDown = false">
              My Orders
            </span>
          </router-link>-->
        </li>
        <li>
          <router-link to="/logout">
            <span @click="showDropDown = false">Logout</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { mixin as clickaway } from "vue-clickaway";

export default {
  mixins: [clickaway],
  template: '<p v-on-clickaway="away">Click away</p>',
  computed: {
    ...mapState(["token", "user_profile"]),
  },
  mounted(){
    //
  },

  // watch: {
  //   fundResponse: function(){
  //     if(this.fundResponse.length>0){
  //       this.paymentRequest =this.fundResponse;
  //     }
  //   },
  // },

  created() {
    if (this.$store.state.token != "" || this.$store.state.token) {
      this.listProfile();
    }
  },

  data() {
    return {
      adminPanelUrl: process.env.VUE_APP_ADMIN_PANEL,
      apiBaseUrl:  process.env.VUE_APP_API_BASE_IP,
      showDropDown: false,
      links: [
        {
          name: "Account",
        },
        {
          name: "Profile",
        },
        {
          name: "Logout",
        },
      ],
      currentDate: Math.round(+new Date()/1000),
      subString: "",
    };
  },

  methods: {
    ...mapActions(["listProfile"]),
    // Hide dropdown on clicking outside it
    away: function () {
      console.log("clicked away");
    },
    click: function () {
      this.showDropDown = true;
    },
    away: function () {
      this.showDropDown = false;
    },
  },
};
</script>

<style lang="scss">
</style>
