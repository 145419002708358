<template>
  <section class="home-banner" v-if="bannerImage">
    <b-carousel
      id="carousel-fade"
      :interval="5000"
      style="text-shadow: 0px 0px 2px #000"
      fade
      ref="myCarousel"
    >
      <b-carousel-slide v-for="(banner, index) in bannerImage" :key="index">
        <template #img>
          <a :href="banner.link" target="_blank">
            <img
              class="d-block w-100"
              :src="banner.image"
              :alt="banner.title"
            />
          </a>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <div class="row g-0 my-5 justify-content-end align-items-center">
      <div class="col-1 button" @click="prev">
        <i class="fas fa-angle-left"></i>
      </div>
      <div class="col-1 button" @click="next">
        <i class="fas fa-angle-right"></i>
      </div>
    </div>
    <div class="product-categories">
      <div class="description-card">
        <h1>Product Categories</h1>
        <ul>
          <li
            v-for="(category_obj, index) in categories_list"
            :key="index"
            v-if="checkValidCategory(category_obj)"
          >
            <router-link :to="'/all-products/' + category_obj.name"
              ><span>{{ category_obj.name }}</span></router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div></div>
    <!-- <div class="switch-button">
      <div class="icon"><i class="fas fa-exchange-alt"></i></div>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdcog158T3AMiegySPUBefYaAh38-IGD4wAuPzf1BNkFZNqEw/viewform?fbclid=IwAR2wCTQpf2870e7eE4sHyd8GI3DRE7st5A2V9fOZ3A6cf9lfXJdbQUXNahI"
          target="_blank"
        >
        <span>Switch to classic mode</span>
        </a>
    </div> -->
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";

// import { VueAgile } from "vue-agile";
export default {
  comments: {
    // agile: VueAgile,
  },
  data() {
    return {
      // options: {
      //   autoplay: true,
      //   autoplaySpeed: 7000,
      //   infinite: true,
      //   dots: false,
      //   navButtons: true,
      //   pauseOnHover: true,
      // },
    };
  },
  computed: {
    ...mapState(["categories_list", "banner_list"]),

    bannerImage: function() {
      let banner = [];
      this.banner_list.forEach((element) => {
        if (element.active == true) {
          banner.push(element);
        }
      });
      return banner;
    },
  },

  created() {
    this.listCategories();
    this.listBanner();
  },
  methods: {
    ...mapActions(["listCategories", "listBanner"]),
    prev() {
      this.$refs.myCarousel.prev();
    },
    next() {
      this.$refs.myCarousel.next();
    },
    checkValidCategory(categoryObj) {
      let haveActiveProduct = false;

      let allCategoryList = categoryObj.product_category;
      allCategoryList.forEach((item) => {
        if (!haveActiveProduct) {
          let checkOutOfStock = false;
          let checkNoInventry = false;
          checkOutOfStock = item.product_status == "Available" ? true : false;
          checkNoInventry = parseInt(item.total_quantity) > 1 ? true : false;

          if (checkOutOfStock && checkNoInventry) {
            haveActiveProduct = true;
          }
        }
      });

      return haveActiveProduct;
    },

    // checkActiveBanner(bannerObj) {
    //   let haveActiveBanner = false;

    //   let allBannerList = bannerObj.banner_obj;
    //   allBannerList.forEach((banner) => {
    //     if (!haveActiveBanner) {
    //       let checkActiveBanner = false;
    //       checkActiveBanner = banner.status == "active" ? true : false;
    //       if (checkActiveBanner) {
    //         haveActiveBanner = true;
    //       }
    //     }
    //   });
    //   return haveActiveBanner;
    // },
  },
};
</script>

<style lang="scss"></style>
