<template>
  <div>
    <div class="form-modal">
      <div class="login-form">
        <div class="login-block" @click.stop>
          <div class="div" v-if="openNew">
            <div class="form-heading">
              <h1 class="heading">Are you new ?</h1>
              <div class="close-login" @click="$emit('close')">
                <i class="far fa-times-circle"></i>
              </div>
            </div>
            <div class="form confirm-form">
              <form>
                <h1>If you are new to Green Growth, Please Register!!</h1>
                <div class="submit-area">
                  <button class="submit" @click="$emit('open')">
                    <span>Register Now</span>
                  </button>
                  <!-- <button class="button-link-type" @click="$emit('close')">
                    <span>Cancel</span>
                  </button> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      openNew: true,
    };
  },
};
</script>
<style lang="scss" scoped>
.confirm-form {
  h1 {
    font-size: 18px;
  }
  button {
    width: 100% !important;
  }
}
</style>