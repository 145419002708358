<template>
	<div>
		<section class="home-categories-show-case">
			<div class="gg-container">
				<div class="section-heading">
				<h2>Search Results</h2>
				</div>
				
				<div class="card-wrapper" v-if="search_products_list && search_products_list.length > 0">
					<!-- Product Item Start -->
					<div class="product-card" v-for="(product, index) in search_products_list" :key="index" v-if="product.product_status == 'Available' && product.total_quantity > 0">
						<div class="product-image" id="product-details">
							<router-link :to="{name: 'productdetails', params: {id: product.id}}" class="product-description">
								<img :src="product.image" alt="image" />
							</router-link>
							<div class="preview-product-icons">
								<button class="preview-icon" @click="isPreviewModal(product.id)" title="Preview">
								<i class="far fa-eye"></i>
								</button>
								<button class="add-to-cart-icon" @click="addToCartModal(product)" title="Add Cart">
								<i class="fas fa-cart-plus"></i>
								</button>
							</div>
						</div>
						<div class="description-card">
							<router-link :to="{name: 'productdetails', params: {id: product.id}}" class="product-description"><h1>{{ product.name }}</h1></router-link>
							<span>in <em>{{ product.category.name }}</em></span>
							<div class="product-quantity">
								<p>Rs {{ product.price_out }}</p>
								<span>per {{ product.unit.name }}</span>
							</div>
						</div>
					</div>
					<!-- Product Item Start -->
				</div>
				<div v-else>
					<h2>Sorry No Products Found.</h2>
					<p>Please try to search with different parameter.</p>
				</div>
			</div>
			<ProductPreviewModal
			:tag="single_product"
			v-show="!isPreviewModalVisible"
			@close="isPreviewModalVisible = !isPreviewModalVisible"
			/>
			<AddToCartModal
			:tag_cart="single_product_cart"
			v-show="!isCartModalVisible"
			@close="isCartModalVisible = !isCartModalVisible"
			/>
			<ModalWarning
			:tag="response_message"
			v-show="isModalWarningVisible"
			@close="isModalWarningVisible = !isModalWarningVisible"
			/>
			<ModalSuccess
			:tag="cart_success_message"
			v-show="isModalSuccessVisible"
			@close="isModalSuccessVisible = !isModalSuccessVisible"
			/>
		</section>
		
	</div>
</template>

<script type="text/javascript">
import { mapState } from "vuex";
import ProductPreviewModal from "../components/ProductPreviewModal.vue";
import AddToCartModal from "../components/AddToCartModal.vue";
import ModalWarning from "../components/ModalWarning.vue";
import ModalSuccess from "../components/ModalSuccess.vue";
export default {
	name: 'searchResultNew',
	components: {
		ProductPreviewModal,
		AddToCartModal,
		ModalWarning,
		ModalSuccess,
	},
	data() {
		return {
			search_value: '',
			search_products_list: [],
			isPreviewModalVisible: true,
			isCartModalVisible: true,
			single_product: [],
			single_product_cart: [],
			isModalWarningVisible: false,
			isModalSuccessVisible: false,
      		cart_success_message: "Product Successfully Added in Cart."
		}
	},
	computed: {
		...mapState([
			"error_message",
			"search_product",
			"response_message",
		]),
	},
	created() {
		this.search_value = this.$route.query.search.replace(/(<([^>]+)>)/ig,"")
		this.search_value = (this.search_value == "") ? 'a' : this.search_value
		this.$store.dispatch("searchProducts", this.search_value)
		.then(() => {
			this.search_products_list = this.search_product
		});
	},
	methods: {
		isPreviewModal(product_id) {
	      this.single_product = this.search_products_list.find(
	        (evt) => evt.id == product_id
	      );

	      this.isPreviewModalVisible = false;
	    },
	    addToCartModal(product){
	        let cartItem = {
	          'id': product.id,
	          'name': product.name,
	          'image': product.image,
	          'quantity': 1,
	          'price': product.price_out,
	          'update': false
	        };
	        this.$store.commit('addUpdateToCart', cartItem);
	        if (this.error_message == "errormessage") {
	          this.isModalWarningVisible = true
	        }else{
				this.isModalSuccessVisible = true
			}
	    },
	},
}
</script>