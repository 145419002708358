<template>
  <div class="gg-container">
    <loading :active.sync="isLoading" :can-cancel="true"> </loading>
    <div class="billing-card">
      <div class="billing-purchase-card">
        <div class="your-order-section">
          <h1 class="your-order-area-heading">your order</h1>
          <div class="calculation-heading">
            <div class="product-name">product</div>
            <div class="quantity-price">
              <span>quantity</span>
              <span>price</span>
            </div>
          </div>
          <div v-if="product_in_cart && product_in_cart.length > 0">
            <div
              v-for="(product_obj, index) in product_in_cart"
              :key="index"
              class="product-info"
            >
              <div class="product-name">{{ product_obj.name }}</div>
              <div class="quantity-price">
                <span class="quantity-inc-dec">{{ product_obj.quantity }}</span>
                <span
                  >NPR.
                  {{
                    (product_obj.price * product_obj.quantity).toFixed(2)
                  }}</span
                >
              </div>
            </div>

            <div class="product-info">
              <div class="product-name"><strong>Delivery Charge</strong></div>
              <div class="quantity-price">
                <span class="quantity-inc-dec">&nbsp;</span>
                <span>NPR. {{ order_delivery_charge }}</span>
              </div>
            </div>
          </div>

          <div class="total-info">
            <span>Total</span>
            <span class="text-color-red"
              >NPR. {{ (cartTotal + order_delivery_charge).toFixed(2) }}/-</span
            >
          </div>
        </div>

        <div class="customer-login-section">
          <div class="customer-register-box" v-if="token == ''">
            <div class="log-in-section">
              <span class="white-text">already a customer ?</span>
              <button class="button" type="submit" id="log-in">
                <span>Log In</span>
              </button>
            </div>
            <div class="or-section">
              <span>or</span>
            </div>
            <div class="register-button-section">
              <button class="button" type="submit" id="join">
                <span>register</span>
              </button>
            </div>
          </div>
          <div class="billing-info-heading">
            <h1>Choose Billing Information</h1>
          </div>
          <form id="contactform" @submit.prevent="">
            <div class="form-dropdown">
              <div class="select-district">
                <select
                  class="district"
                  v-model="choose_billing"
                  @change="changeOnBillingAddress($event)"
                >
                  <option value="new" selected>
                    New Billing Information (+)
                  </option>
                  <option
                    v-for="(address, index) in billing_address_obj"
                    :key="index"
                    :value="address.id"
                  >
                    {{ address.first_name }} {{ address.middle_name }}
                    {{ address.last_name }} ({{ address.street_address }},
                    {{ address.city }})
                  </option>
                </select>
                <span style="color: red" v-if="errors.choose_billing"
                  >{{ errors.choose_billing }}<br /><br
                /></span>
              </div>
            </div>
            <!-- starts here -->
            <div v-show="show_billing_form">
              <div class="name">
                <div class="first-name">
                  <input
                    id="firstName"
                    v-model="first_name"
                    placeholder="First name"
                    required
                    tabindex="1"
                    type="text"
                  />
                  <label for="firstName">First Name</label>
                  <span style="color: red" v-if="errors.first_name"
                    >{{ errors.first_name }}<br /><br
                  /></span>
                </div>
                <div class="middle-name">
                  <input
                    id="middleName"
                    v-model="middle_name"
                    placeholder="Middle name"
                    tabindex="1"
                    type="text"
                  />
                  <label for="middleName">Middle Name</label>
                </div>
                <div class="last-name">
                  <input
                    id="lastName"
                    v-model="last_name"
                    placeholder="last name"
                    required
                    tabindex="1"
                    type="text"
                  />
                  <label for="lastName">Last Name</label>
                  <span style="color: red" v-if="errors.last_name"
                    >{{ errors.last_name }}<br /><br
                  /></span>
                </div>
              </div>
              <div class="contact">
                <div class="email">
                  <input
                    id="email"
                    v-model="email"
                    placeholder="example@domain.com"
                    required
                    type="email"
                  />
                  <label for="email">Email</label>
                  <span style="color: red" v-if="errors.email"
                    >{{ errors.email }}<br /><br
                  /></span>
                </div>
                <div class="phone">
                  <input
                    id="phone"
                    v-model="phone"
                    placeholder="phone number"
                    required
                    type="text"
                  />
                  <label for="phone">Mobile phone</label>
                  <span style="color: red" v-if="errors.phone"
                    >{{ errors.phone }}<br /><br
                  /></span>
                </div>
              </div>
              <div class="form-dropdown">
                <div class="select-zone">
                  <select class="zone" v-model="province_selected">
                    <option value="">Select Province</option>
                    <option
                      v-for="(province, index) in provinces"
                      :key="index"
                      :value="province.name"
                    >
                      {{ province.name }}
                    </option>
                  </select>
                  <span style="color: red" v-if="errors.province_selected"
                    >{{ errors.province_selected }}<br /><br
                  /></span>
                </div>
                <div class="select-district">
                  <select class="district" v-model="district_selected">
                    <option value="" disabled>Select District</option>
                    <option
                      v-for="(district, index) in districts"
                      :key="index"
                      :value="district"
                    >
                      {{ district }}
                    </option>
                  </select>
                  <span style="color: red" v-if="errors.district_selected"
                    >{{ errors.district_selected }}<br /><br
                  /></span>
                </div>
              </div>
              <div class="address-field">
                <div class="city-address-name-field">
                  <input
                    id="cityAddress"
                    v-model="street_address"
                    placeholder="your Street Address.."
                    required
                    tabindex="1"
                    type="text"
                  />
                  <label for="cityAddress">Street Address</label>
                  <span style="color: red" v-if="errors.street_address"
                    >{{ errors.street_address }}<br /><br
                  /></span>
                </div>
                <div class="street-address-name-field">
                  <input
                    id="streetAddress"
                    v-model="country"
                    placeholder="your current street.."
                    required
                    tabindex="1"
                    type="text"
                    disabled
                  />
                  <label for="streetAddress">Country</label>
                </div>
              </div>
              <div class="map-section">
                <div @click="openPinLocation" class="pin-button">
                  Pin Your Location <i class="fas fa-arrow-right"></i>
                </div>
                <collapse-transition>
                  <div v-show="isOpen" class="batoo-map">
                    <!-- -->
                    <div>
                      <label for="searchplaceholder"
                        >Type location to pin your address</label
                      >
                      <input
                        type="text"
                        id="searchplaceholder"
                        v-model="placename"
                        @input="search(placename)"
                        placeholder="Search for a place..."
                        style="
                          width: 98%;
                          background: #f0f0f0;
                          padding: 10px 0px 10px 10px;
                          margin-bottom: 6px;
                          border: 1px #9f9f9f solid;
                          display: inline;
                        "
                      />
                      <select
                        id="text_editors-select"
                        v-model="placeid"
                        autocomplete="nope"
                        style="width: 100%; display: block"
                        @change="searchByPlaceId(placeid)"
                      >
                        <option value="Atom">Suggestions:</option>
                      </select>
                    </div>
                    <!-- -->
                    <div id="response" class="map-location-info">
                      Click anywhere on the map.
                    </div>
                    <div id="map" class="pin-map-location"></div>
                    <i
                      @click="isOpen = !isOpen"
                      class="fas fa-times-circle position-absolute"
                      style=""
                    ></i>
                  </div>
                </collapse-transition>
              </div>
            </div>
            <div v-show="show_billing_container" class="show_billing_container">
              <h3>Selected Delivery Detail:</h3>
              <p>
                <span
                  >{{ this.first_name }} {{ this.middle_name }}
                  {{ this.last_name }}</span
                ><br />
                <span>{{ this.email }}</span
                ><br />
                <span>{{ this.phone }}</span
                ><br />
                <span
                  >{{ this.street_address }}, {{ this.district_selected }},
                  {{ this.province_selected }}</span
                ><br />
              </p>
            </div>
            <!-- end here -->
            <div class="cash-on-delivery">
              <h1>cash on delivery</h1>
              <p>
                Pay with cash upon delivery.
                <br />Your personal data will be used to process your order,
                <br />support your experience throughout this website and
                <br />for other purposes described in our
                <a href="#">privacy policy</a>
                <br />
              </p>
            </div>
            <div class="agreement-field">
              <input
                type="checkbox"
                id="agreement"
                name="agreed"
                v-model="agree"
                value="checkbox"
              />
              <span for="agreement">
                I have read and agree to the website
                <span>
                  <a href>terms and privacy.</a>
                </span>
              </span>
              <span style="color: red" v-if="errors.agree"
                ><br />{{ errors.agree }}</span
              >
              <br />
            </div>
            <div class="submit-button">
              <button type="button" class="button" @click="PlaceOrder">
                Place Order
              </button>
            </div>
          </form>
        </div>
      </div>

      <ModalBillingSuccess
        :tag="response_success"
        v-show="isModalSuccessVisible"
        @close="isModalSuccessVisible = !isModalSuccessVisible"
      />

      <ModalError
        :tag="error_response"
        v-show="isModalErrorVisible"
        @close="isModalErrorVisible = !isModalErrorVisible"
      />
    </div>
  </div>
</template>

<script>
import Baato from "@klltech/baato-js-client";
import mapboxgl from "mapbox-gl";
import { mapState, mapActions, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import province from "../js/districts.js";
import ModalBillingSuccess from "../components/ModalBillingSuccess.vue";
import ModalError from "../components/ModalError.vue";
// import { CollapseTransition } from "@ivanv/vue-collapse-transition"
export default {
  name: "checkoutPage",
  components: {
    CollapseTransition,
    Loading,
    ModalBillingSuccess,
    ModalError,
  },
  data() {
    return {
      isOpen: false, //for the collapsible closed by default
      isLoading: false,
      first_name: "",
      middle_name: "",
      last_name: "",
      country: "Nepal",
      phone: "",
      email: "",
      street_address: "",
      district_selected: "Kathmandu",
      province_selected: "Bagmati Pradesh",
      agree: true,
      provinces: province,
      errors: { auth: "" },
      product_details: [],
      my_delivery_address: null,
      quantity_nunber: 1,
      product_in_cart: JSON.parse(localStorage.getItem("localCartData")),
      cart_total_price: "",
      isModalPinAddressVisible: false,
      isModalSuccessVisible: false,
      isModalErrorVisible: false,
      currentLocation: JSON.parse(localStorage.getItem("currentLocation")),
      response_success: "You have successfuly ordered your products.",
      error_response: "You have to login to order a product.",
      address_id: 1,
      order_delivery_charge: 100,
      errors: [],
      choose_billing: "new",
      show_billing_form: true,
      show_billing_container: false,
      new_delivery_address: "",
      old_delivery_address: "",
      choosed_address: "",
      choosed_ip_address: "",
      placename: "",
      placeid: "",
      searchplaceholder: "Type Address for search location",
      mainMarker: [],
    };
  },
  computed: {
    ...mapState([
      "token",
      "user_profile",
      "user_register_message",
      "billing_address_obj",
      "error_message",
      "success_message",
    ]),
    districts: function () {
      if (this.province_selected != "") {
        return this.provinces.find(
          (province) => province.name == this.province_selected
        ).districts;
      } else {
        return "";
      }
      // return ["Kathmandu", "Lalitpur", "Bhaktapur"];
    },
    cartTotal: function () {
      let total = [];
      Object.entries(this.product_in_cart).forEach(([key, val]) => {
        total.push(val.price * val.quantity);
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },
    getMapInstance: function () {
      mapboxgl.accessToken =
        "pk.eyJ1IjoiaGlzdWJlZGlzdXNoaWwiLCJhIjoiY2tsYnlicXZyMW80ZTJwbnBmdTEyNDhoayJ9.gPdIT5tO9cI55mlBouyYYw";
      var newMap = new mapboxgl.Map({
        container: "map",
        style:
          "https://api.baato.io/api/v1/styles/breeze?key=bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu", // Baato stylesheet location
        center: [85.324, 27.7172], // starting position [lng, lat]
        zoom: 10, // starting zoom
      });
      return newMap;
    },
  },
  created() {
    if (
      this.$store.state.token == "" ||
      this.$store.state.token == null ||
      this.product_in_cart.length <= 0
    ) {
      this.$router.push({
        name: "mycartproducts",
      });
    }
    this.listProfile();
    this.listMyBillingAddress();
  },
  watch: {
    user_profile: function () {
      if (this.user_profile) {
        this.first_name = this.user_profile["first_name"];
        this.middle_name = this.user_profile["middle_name"];
        this.last_name = this.user_profile["last_name"];
        this.phone = this.user_profile["phone_number"];
        this.email = this.user_profile["email"];
        this.street_address = this.user_profile.user_info.street_address;
        this.district_selected = this.user_profile.user_info.city;
        // console.log(this.district_selected);
      }
    },
    product_list: function () {
      if (this.$route.params.id) {
        this.product_details = this.product_list.find(
          (evt) => evt.id == this.$route.params.id
        );
      }
    },
  },
  methods: {
    ...mapActions(["listProfile", "listMyBillingAddress"]),
    changeOnBillingAddress(event) {
      this.choose_billing = event.target.value;
      if (this.choose_billing == "new") {
        this.first_name = this.user_profile["first_name"];
        this.middle_name = this.user_profile["middle_name"];
        this.last_name = this.user_profile["last_name"];
        this.phone = this.user_profile["phone_number"];
        this.email = this.user_profile["email"];
        // this.district_selected = "";
        // this.street_address = "";

        this.show_billing_form = true;
        this.show_billing_container = false;
      } else {
        //alert(this.choose_billing)
        let addressData = this.billing_address_obj
          .filter((obj) => {
            return obj.id === parseInt(this.choose_billing);
          })
          .pop();
        this.first_name = addressData.first_name;
        this.middle_name = addressData.middle_name;
        this.last_name = addressData.last_name;
        this.email = addressData.email;
        this.phone = addressData.phone;
        this.district_selected = addressData.city;
        this.street_address = addressData.street_address;

        this.show_billing_form = false;
        this.show_billing_container = true;
      }
      // this.show_hide_billing = this.cancel_option == 'Partial' ? true : false
    },
    PlaceOrder() {
      this.errors = [];
      if (this.choose_billing == "") {
        this.errors["choose_billing"] = "Choose billing information.";
      } else if (this.first_name == "") {
        this.errors["first_name"] = "First name required";
      } else if (this.last_name == "") {
        this.errors["last_name"] = "Last name required";
      } else if (this.email == "") {
        this.errors["email"] = "Email required";
      } else if (this.phone == "") {
        this.errors["phone"] = "Phone required";
      } else if (!this.province_selected || this.province_selected == "") {
        this.errors["province_selected"] = "Province required";
      } else if (!this.district_selected || this.district_selected == "") {
        this.errors["district_selected"] = "District required";
      } else if (this.street_address == "") {
        this.errors["street_address"] = "Street addreess required";
      } else if (!this.agree) {
        this.errors["agree"] = "Please agree on terms and condition";
      } else {
        this.isLoading = true;

        if (this.choose_billing == "new") {
          this.new_delivery_address = 1;
          this.old_delivery_address = "";
        } else {
          this.new_delivery_address = 0;
          this.old_delivery_address = this.choose_billing;
        }

        let totalItems =
          this.product_in_cart && this.product_in_cart.length > 0
            ? this.product_in_cart.length
            : 0;
        if (totalItems > 0) {
          console.log("Choose IP: ", this.choosed_ip_address);
          /* Delivery Address Start */
          // this.$store.dispatch("createDeliveryAddress", formData).then(() => {
          //   if (this.success_message == "success") {
          /* Create Delivery Address Obj */
          let deliveryAddressObj = [
            {
              first_name: this.first_name,
              middle_name: this.middle_name,
              last_name: this.last_name,
              phone: this.phone,
              email: this.email,
              country: 1,
              city: this.district_selected,
              state: this.province_selected,
              street_address: this.street_address,
              latitude:
                this.choosed_ip_address.length == 2
                  ? this.choosed_ip_address[1]
                  : !this.currentLocation
                  ? "24.4434343434"
                  : this.currentLocation.latitude,
              longitude:
                this.choosed_ip_address.length == 2
                  ? this.choosed_ip_address[0]
                  : !this.currentLocation
                  ? "27.232232323"
                  : this.currentLocation.longitude,
            },
          ];
          /* Start Order Process */
          let orderTotalPrice = this.cartTotal;
          let taxVatCharge = 0.0;
          let deliveryCharge = this.order_delivery_charge;
          let ipAddress = "127.1.1.1";
          let currencyId = 1;
          let paymentType = 1;
          let orderProduct = [];
          for (var i = 0; i < totalItems; i++) {
            let productItem = {
              product: this.product_in_cart[i].id,
              unit: this.product_in_cart[i].unit,
              quantity: this.product_in_cart[i].quantity,
              price: this.product_in_cart[i].price,
            };
            orderProduct.push(productItem);
          }
          let orderObjValue = {
            order_price: orderTotalPrice.toFixed(2),
            tax_vat_price: taxVatCharge.toFixed(2),
            delivery_charge: deliveryCharge.toFixed(2),
            ip_address: ipAddress,
            delivery_address: deliveryAddressObj,
            currency: currencyId,
            payment_type: paymentType,
            order_product: orderProduct,
          };

          let finalOrderObjValue = {
            data: JSON.stringify(orderObjValue),
            new_delivery_address: this.new_delivery_address,
            old_delivery_address: this.old_delivery_address,
          };

          console.log(orderObjValue);
          console.log(finalOrderObjValue);

          this.$store
            .dispatch("createNewOrder", finalOrderObjValue)
            .then(() => {
              if (this.success_message == "success") {
                this.$store.commit("restoreCart");
                localStorage.removeItem("localCartData");
                this.isLoading = false;
                this.isModalSuccessVisible = true;
                location.reload();
              } else {
                this.isLoading = false;
              }
            });

          /* End Order Process */
          //   }
          // });
          /* Delivery Address Ends */
        } //if (totalItems > 0)
      }
    },
    openPinLocation() {
      //
      this.isOpen = false;
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.isOpen = true;
      }, 1000);
    },
    clearMarkers(markers) {
      markers.forEach((marker) => marker.remove());
      markers = [];
    },
    search(query) {
      new Baato.Search()
        .setBaseUrl(`https://api.baato.io/api`) // Baato base URL
        .setKey("bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu") // Baato access token
        .setQuery(query) // query to search for
        .setLimit(5) // limit the number of responses
        .doRequest()
        .then((res) => {
          var select = document.getElementById("text_editors-select");
          var length = select.options.length;
          for (i = length - 1; i >= 0; i--) {
            select.options[i] = null;
          }
          for (var i in res.data) {
            select.add(new Option(res.data[i].name, res.data[i].placeId));
          }
          document.getElementById("text_editors-select").size = 5;
        });
    },
    searchByPlaceId(placeId) {
      var markers = [];
      var map = this.getMapInstance;
      new Baato.Places()
        .setBaseUrl(`https://api.baato.io/api`) // Baato base URL
        .setPlaceId(placeId) // PlaceId for the place to search
        .setKey("bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu") // your Baato access key
        .doRequest()
        .then((res) => {
          this.clearMarkers(markers);
          var marker = new mapboxgl.Marker()
            .setLngLat([res.data[0].centroid.lon, res.data[0].centroid.lat]) // setting latitude and longtitude for marker
            .addTo(map);
          markers.push(marker);

          if (
            res.data[0].geometry.coordinates[0] &&
            res.data[0].geometry.coordinates[0].length > 1
          ) {
            this.choosed_ip_address = res.data[0].geometry.coordinates[0][0];
          } else {
            this.choosed_ip_address = res.data[0].geometry.coordinates;
          }

          document.getElementById("response").innerHTML = res.data[0].address;
        });
    },
  },
  mounted: function () {
    //Get IP and Get Lat, Long
    var markers = [];
    var map = this.getMapInstance;

    map.on("click", (e) => {
      new Baato.Reverse()
        .setBaseUrl(`https://api.baato.io/api`) // Baato base URL
        .setCoordinates([e.lngLat.lat, e.lngLat.lng]) // [lng, lat] for reverse geocoding
        .setKey("bpk.TqsQJxeMXGKW-50fsE0ZpFkMxcKlwOWgvUnU2AFdmluu") // Baato access token
        .setRadius(0.2)
        .doRequest()
        .then((res) => {
          this.clearMarkers(markers);
          var marker = new mapboxgl.Marker()
            .setLngLat([res.data[0].centroid.lon, res.data[0].centroid.lat]) // adding marker for the response [lon, lat]
            .addTo(map);
          markers.push(marker);

          //this.street_address = res.data[0].address
          //this.choosed_address = res.data[0].name
          if (
            res.data[0].geometry.coordinates[0] &&
            res.data[0].geometry.coordinates[0].length > 1
          ) {
            this.choosed_ip_address = res.data[0].geometry.coordinates[0][0];
          } else {
            this.choosed_ip_address = res.data[0].geometry.coordinates;
          }
          document.getElementById("response").innerHTML = res.data[0].address;
          // document.getElementById("response").innerHTML =
          //   "<b>" +
          //   JSON.stringify(res) +
          //   "</b>" +
          //   "<br/><br/>" +
          //   "<b>" +
          //   "Address: " +
          //   "</b>" +
          //   res.data[0].address;
        });
    });
  },
};
</script>
<style scoped>
.agreement-field {
  display: flex;
}
input {
  margin-right: 5px;
}
.show_billing_container {
  width: 100%;
  background: #e8e7e7;
  padding: 10px;
  border: 1px #cacaca solid;
}
</style>
<style lang="scss" scoped>
.map-section {
  .pin-button {
    // height: 45px;
    // background: #4ba069;
    padding: 2px;
    // width: 220px;
    color: #4ba069;
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-top: 0px;
    cursor: pointer;
    font-weight: 700;
    padding-right: 10px;
  }
  i {
    font-size: 14px;
    padding: 0px 5px;
  }
}
.batoo-map {
  margin-top: 10px;
  position: relative;
  #map {
    width: 100%;
  }
  .map-location-info {
    z-index: 1;
    border: 1px solid #4ba069;
    padding: 10px;
    margin-bottom: 10px;
    background: rgba(255, 255, 255, 0.75);
  }
  .pin-map-location {
    border: 1px solid #4ba069;
    height: 250px;
    // width: 100%;
  }
  i {
    font-size: 24px;
    // color:#4ba069;
    cursor: pointer;
  }
  .position-absolute {
    position: absolute;
    top: -30px;
    right: 0px;
    color: #d70000;
  }
  .mapboxgl-canvas {
    width: fill-available !important;
  }
}
</style>
