<template>
  <div class="outlets">
    <aside class="outlets-img">
      <img src="@/assets/images/farmer.png" alt />
    </aside>
    <div class="location">
      <aside class="content">
        <h1>Green Growth Source - Hariwon</h1>
        <div class="subtitle">
          <div>Location</div>
          <div>Hariwon-10, Sarlahi</div>
        </div>
        <div class="subtitle">
          <div>Key Person</div>
          <div>Ashish Ghimire,  Amit Ghimire</div>
        </div>
        <div class="subtitle">
          <span>Contact Number</span>
          <span>9807677430, 9812658045</span>
        </div>
      </aside>
      <aside class="address">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.649196064226!2d85.34508882856332!3d27.66632330347705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19f95ee58aff%3A0x18c88ee802315e15!2sKathmandu%20Valley!5e0!3m2!1sen!2snp!4v1595387979597!5m2!1sen!2snp" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </aside>
    </div>
  <HomeCategoriesShowCase/>
  </div>
</template>

<script>
import HomeCategoriesShowCase from '../components/HomeCategoriesShowCase.vue';

export default {
  components:{
    HomeCategoriesShowCase,
  }
}
</script>