<template>
    <div class="continue-button">
        <router-link :to="{name: 'allproducts', params:{id: 'All'}}">
            <span>continue shopping</span>
        </router-link>
    </div>
</template>
<script>
export default {
};
</script>
<style>
</style>
