<template>
  <div class="container-form">
    <div class="form" @click.stop>
      <div class="heading-items">
        <h1 class="heading">Registration Form</h1>
        <p class="close" @click="$emit('close')">
          <i class="far fa-times-circle"></i>
        </p>
      </div>
      <div></div>
      <form id="contactForm">
        <div class="name">
          <div class="first-name form-input-wrap">
            <input
              id="first_name"
              v-model="first_name"
              placeholder="First name"
              required
              tabindex="1"
              type="text"
            />
            <label for="first_name">First Name*</label>
            <span class="form-error" v-if="errors.first_name">{{
              errors.first_name
            }}</span>
          </div>
          <div class="middle-name form-input-wrap">
            <input
              id="middle_name"
              v-model="middle_name"
              placeholder="Middle name"
              tabindex="1"
              type="text"
            />
            <label for="middle_name">Middle Name</label>
          </div>
          <div class="last-name form-input-wrap">
            <input
              id="last_name"
              v-model="last_name"
              placeholder="last name"
              required
              tabindex="1"
              type="text"
            />
            <label for="last_name">Last Name*</label>
            <span class="form-error" v-if="errors.last_name">{{
              errors.last_name
            }}</span>
          </div>
        </div>
        <div class="email-phone">
          <div class="email form-input-wrap">
            <input
              id="email"
              v-model="email"
              placeholder="example@domain.com"
              required
              tabindex="1"
              type="email"
            />
            <label for="email">Email*</label>
            <span class="form-error" v-if="errors.email">{{
              errors.email
            }}</span>
          </div>
          <div class="phone form-input-wrap">
            <input
              id="phone"
              v-model="phone"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              placeholder="phone number"
              required
              type="text"
              minlength="10"
              maxlength="10"
              tabindex="1"
            />
            <label for="phone">Mobile phone*</label>
            <span class="form-error" v-if="errors.phone">{{
              errors.phone
            }}</span>
          </div>
        </div>
        <div class="password">
          <div class="new-passwor">
            <!-- <i :class="'fas '+password_eye+' move_to_up'" @click="showHidePassword"></i>
            <input
              placeholder="Create a password"
              :type="password_type"
              id="password"
              v-model="password"
              tabindex="1"
              required
            />
            <label for="password">Create a password</label> -->
            <md-field>
              <label class="password-label">Create a password</label>
              <md-input
                v-model="password"
                type="password"
                placeholder="Create a password"
                id="password"
                tabindex="1"
                required
              ></md-input>
            </md-field>
          </div>
          <div class="re-enter-passwor">
            <!-- <i :class="'fas '+conf_password_eye+' move_to_up'" @click="showHideConfPassword"></i>
            <input
              placeholder="Conform your password"
              :type="conf_password_type"
              id="repassword"
              tabindex="1"
              v-model="confirm_password"
              required
            />
            <label for="repassword">Confirm your password</label> -->
            <md-field>
              <label class="password-label">Confirm your password</label>
              <md-input
                v-model="confirm_password"
                type="password"
                placeholder="Confirm your password"
                id="repassword"
                tabindex="1"
                required
              ></md-input>
            </md-field>
          </div>
        </div>
        <span class="form-error" v-if="errors.password">{{
          errors.password
        }}</span>
        <span class="form-error" v-if="errors.confirm_password">{{
          errors.confirm_password
        }}</span>
        <div class="user-age" id="user-age">
          <input
            type="checkbox"
            id="iamover13"
            v-model="agreed"
            value="checkbox"
            tabindex="1"
          />
          <span for="iamover13">I am over 13 years old.</span>
          <span class="form-error" v-if="errors.agreed">{{
            errors.agreed
          }}</span>
        </div>
        <!-- <div class="address-city form-input-wrap">
          <input
            id="city-address"
            v-model="country"
            placeholder="your country.."
            required
            tabindex="1"
            type="text"
            disabled
          />
          <label for="name">Country</label>
        </div>

        <select class="country" v-model="province_selected">
          <option value disabled>Select Province</option>
          <option v-for="(province, index) in provinces" :key="index" :value="province.name">{{ province.name }}</option>
        </select>
        <select class="zone" v-model="district_selected">
          <option value disabled>Select District</option>
          <option v-for="(district,index) in districts" :key="index" :value="district">{{ district }}</option>
        </select>

        <div class="address-street form-input-wrap">
          <input
            id="city-address"
            v-model="street_address"
            placeholder="your current address.."
            required
            tabindex="1"
            type="text"
          />
          <label for="name">Street Address</label>
        </div> -->
        <div class="agreement-field" id="agreement-field">
          <input
            type="checkbox"
            id="agreement"
            v-model="agreed1"
            value="checkbox"
            tabindex="1"
          />
          <span for="agreement">
            I agree to account
            <router-link
              :to="{
                name: 'staticPage',
                params: { slug: 'terms-and-conditions' },
              }"
              class="privacy-policy-link"
              target="_blank"
              >terms and privacy </router-link
            >.
          </span>
          <span class="form-error" v-if="errors.agreed1">{{
            errors.agreed1
          }}</span>
          <br />
        </div>
        <div>
          <p class="form-error" v-if="errors.register_error">
            {{ errors.register_error }}
          </p>
          <p style="color: green" v-if="register_success">
            Register Successfully!
          </p>
        </div>
        <div class="submit-button">
          <button type="button" class="button" @click="RegisterForm">
            Register now
          </button>
        </div>
        <div class="login-area">
          <p>
            Already have an account?
          </p>
          <button @click ="$emit('open')">
            <span>Log In</span>
          </button>
        </div>
      </form>
    </div>
    <input type="hidden" v-model="state_change_value" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import province from "../js/districts.js";

export default {
  computed: {
    ...mapState([
      "successmessage",
      "error_message",
      "message",
      "user_register_message",
      "response_message",
    ]),

    districts: function () {
      if (this.province_selected != "") {
        return this.provinces.find(
          (province) => province.name == this.province_selected
        ).districts;
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      first_name: "",
      middle_name: "",
      last_name: "",
      username: "",
      email: "",
      password: "",
      confirm_password: "",
      phone: "",
      country: "Nepal",
      zone: "",
      city_address: "",
      street_address: "",
      errors: [],
      condition: "",
      strongpasword: "",
      wrongpassword: "",
      passworderror: "",
      agreed: true,
      agreed1: true,
      district_selected: "",
      province_selected: "",
      provinces: province,
      valid_password:
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/,
      errors: [],
      // password_eye: 'fa-eye-slash',
      // password_type: 'password',
      // conf_password_eye: 'fa-eye-slash',
      // conf_password_type: 'password',
      register_success: false,
      state_change_value: "",
      phoneReg: /^([(+]*[0-9]+[()+. -]*){10,10}$/,
    };
  },

  methods: {
    RegisterForm() {
      this.errors = [];
      if (this.first_name == "") {
        this.errors["first_name"] = "First Name is required.";
      } else if (this.last_name == "") {
        this.errors["last_name"] = "Last Name is required.";
      } else if (this.email == "") {
        this.errors["email"] = "Email is required.";
      } else if (this.phone == "") {
        this.errors["phone"] = "Phone Number is required.";
      } else if (!this.phoneReg.test(this.phone)) {
        this.errors["phone"] = "Phone Number must have 10 digits.";
      } else if (this.agreed == "") {
        this.errors["agreed"] = "This field is required.";
      } else if (this.agreed1 == "") {
        this.errors["agreed1"] = "This field is required.";
      } else if (this.password == "") {
        this.errors["password"] = "Password is required.";
      } else if (!this.valid_password.test(this.password)) {
        this.errors["password"] =
          "Password must contain 6 characters with a capital & small letter, number & special character.";
      } else if (this.confirm_password == "") {
        this.errors["confirm_password"] = "Confirm Password is required.";
      } else if (this.password != this.confirm_password) {
        this.errors["confirm_password"] = "Passwords did not match.";
      } else {
        let formData = new FormData();
        formData.append("first_name", this.first_name);
        formData.append("middle_name", this.middle_name);
        formData.append("last_name", this.last_name);
        formData.append("phone_number", this.phone);
        formData.append("email", this.email);
        formData.append("group", 1);
        formData.append("password", this.password);
        formData.append("confirm_password", this.confirm_password);

        for (var pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        this.$store.dispatch("createUser", formData).then(() => {
          if (this.user_register_message == "success") {
            localStorage.removeItem("activatePageReloaded");
            this.state_change_value = Date.now();
            this.register_success = true;
            this.$router.push("/activate");
          } else if (this.error_message == "errormessage") {
            this.state_change_value = Date.now();
            this.errors["register_error"] = this.response_message;
          }
        });
      }
    },
    // showHidePassword(){
    //   if(this.password_type == 'password'){
    //     this.password_eye = 'fa-eye'
    //     this.password_type = 'text'
    //   }else{
    //     this.password_eye = 'fa-eye-slash'
    //     this.password_type = 'password'
    //   }
    // },
    // showHideConfPassword(){
    //   if(this.conf_password_type == 'password'){
    //     this.conf_password_eye = 'fa-eye'
    //     this.conf_password_type = 'text'
    //   }else{
    //     this.conf_password_eye = 'fa-eye-slash'
    //     this.conf_password_type = 'password'
    //   }
    // },
  },
};
</script>

<style scoped>
.container-form .form {
  pointer-events: all;
}
</style>

<style lang="scss">
.privacy-policy-link {
  color: #6767ff !important;
  &:hover {
    text-decoration: underline;
  }
}
</style>
