<template>
  <section class="home-exclusive" v-if="exclusive_product == 1">
    <div class="gg-container">
      <h2 class="section-heading">exclusive</h2>
      <div class="card-wrapper">
        <div
          class="product-card"
          v-for="(product_obj, index) in filterCategoryProductList(
            product_list
          )"
          :key="index"
        >
          <div class="product-image" id="product-details">
            <router-link
              :to="{
                name: 'productdetails',
                params: { id: product_obj.id },
              }"
              class="product-description"
            >
              <img :src="product_obj.image" alt="image" />
            </router-link>

            <div class="preview-product-icons">
              <button
                class="preview-icon"
                @click="isPreviewModal(product_obj.id)"
                title="Preview"
              >
                <i class="far fa-eye"></i>
              </button>
              <button
                class="add-to-cart-icon"
                @click="addToCartModal(product_obj)"
                title="Add Cart"
              >
                <i class="fas fa-cart-plus"></i>
              </button>
            </div>
          </div>
          <div class="description-card">
            <router-link
              :to="{
                name: 'productdetails',
                params: { id: product_obj.id },
              }"
              class="product-description"
            >
              <h1>{{ product_obj.name }}</h1>
            </router-link>
            <div class="product-quantity">
              <p>Rs {{ product_obj.price_out }}</p>
              <span>per {{ product_obj.unit.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProductPreviewModal
      :tag="single_product"
      v-show="!isPreviewModalVisible"
      @close="isPreviewModalVisible = !isPreviewModalVisible"
    />
    <AddToCartModal
      :tag_cart="single_product_cart"
      v-show="!isCartModalVisible"
      @close="isCartModalVisible = !isCartModalVisible"
    />
    <ModalWarning
      :tag="response_message"
      v-show="isModalWarningVisible"
      @close="isModalWarningVisible = !isModalWarningVisible"
    />
    <ModalSuccess
      :tag="cart_success_message"
      v-show="isModalSuccessVisible"
      @close="isModalSuccessVisible = !isModalSuccessVisible"
    />
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProductPreviewModal from "./ProductPreviewModal.vue";
import AddToCartModal from "./AddToCartModal.vue";
import ModalWarning from "../components/ModalWarning.vue";
import ModalSuccess from "../components/ModalSuccess.vue";


export default {
  name: "HomeExclusive",
  components: {
    ProductPreviewModal,
    AddToCartModal,
    ModalWarning,
    ModalSuccess,
  },

  data() {
    return {
      isPreviewModalVisible: true,
      isCartModalVisible: true,
      single_product: [],
      single_product_cart: [],
      isModalWarningVisible: false,
      isModalSuccessVisible: false,
      selected_unit_obj: "",
      cart_success_message: "Product Successfully Added in Cart.",
      exclusive_product: 0,
    };
  },
  computed: {
    ...mapState(["product_list", "error_message", "response_message"]),
  },

  watch: {
    product_list: function () {
      if (this.product_list.length > 0) {
        var is_exclusive = 0;
        this.$store.state.product_list.forEach(function (rec) {
          if (rec.exclusives == "active") {
            is_exclusive = 1;
          }
        });
        this.exclusive_product = is_exclusive;
      }
    },
  },
  created() {
    this.listProducts();
  },
  methods: {
    ...mapActions(["listProducts"]),

    isPreviewModal(product_id) {
      this.single_product = this.product_list.find(
        (evt) => evt.id == product_id
      );

      this.isPreviewModalVisible = false;
    },
    addToCartModal(product) {
      //
      this.selected_unit_obj = product.product_unit.find(
        (evt) => evt.unit.id == product.unit.id
      );
      let cartItem = {
        id: product.id,
        name: product.name,
        unit: this.selected_unit_obj.unit.id,
        unitname: this.selected_unit_obj.unit.name,
        image: product.image,
        quantity: 1,
        price: product.price_out,
        update: false,
      };
      this.$store.commit("addUpdateToCart", cartItem);
      if (this.error_message == "errormessage") {
        this.isModalWarningVisible = true;
      } else {
        this.isModalSuccessVisible = true;
      }
    },
    filterCategoryProductList(product_list_obj) {
      let product_data = product_list_obj;
      //product_obj.category.name.toLowerCase()=='exclusive'
      product_data = product_data.filter((row) => {
        return (
          row.product_status == "Available" &&
          row.total_quantity > 0 &&
          row.exclusives.toLowerCase() == "active"
        );
      });
      return product_data.slice(0, 12);
    },
  },
};
</script>

<style scoped>
.product-card {
  margin-bottom: 15px;
}
</style>
