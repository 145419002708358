<template>
  <!-- <div class="login-form login-form--hidden"> -->
  <div>
    <loading :active.sync="isLoading" :can-cancel="false"> </loading>
    <div class="form-modal">
      <div class="login-form">
        <div class="login-block" @click.stop>
          <div class="login-contents" v-if="openLogin">
            <span style="color: red" v-if="errors.auth">{{ errors.auth }}</span>
            <span style="color: green" v-if="password_reset_success"
              >Password re-set success, please login now.</span
            >
            <div class="form-heading">
              <h1 class="heading">Login Form</h1>
              <div class="close-login" @click="$emit('close')">
                <i class="far fa-times-circle"></i>
              </div>
            </div>

            <div class="form">
              <form>
                <div class="login-username">
                  <input
                    type="email"
                    id="login-username"
                    v-model="email"
                    placeholder="Email"
                    @keyup.enter="processLogin"
                    required
                  />
                  <label for="login-username">Email*</label>
                  <span style="color: red" v-if="errors.email_required">{{
                    errors.email_required
                  }}</span>
                </div>

                <div class="login-password">
                  <!-- <i
                    :class="'fas ' + password_eye + ' move_to_up'"
                    @click="showHidePassword"
                  ></i>
                  <input
                    :type="password_type"
                    id="login-password"
                    placeholder="Password"
                    v-model="password"
                    @keyup.enter="processLogin"
                    required
                  />
                  <span style="color: red" v-if="errors.password_required">{{
                    errors.password_required
                  }}</span> -->
                  <md-field>
                    <!-- <label>Password</label> -->
                    <label class="password-label">Password</label>
                    <md-input
                      v-model="password"
                      type="password"
                      id="login-password"
                      placeholder="Password"
                      @keyup.enter="processLogin"
                      required
                    ></md-input>
                  </md-field>
                  <span style="color: red" v-if="errors.password_required">{{
                    errors.password_required
                  }}</span>
                </div>
              </form>
            </div>
            <div class="submit-area">
              <button class="submit" @click="processLogin">
                <span>Log In</span>
              </button>
              <button
                class="button-link-type"
                @click="(openPasswordReset = true), (openLogin = false)"
              >
                <span>Forgot your password?</span>
              </button>
            </div>
            <div class="register-area">
              <p
                >Don’t have an account? Create your Account, it takes a less
                than a minute</p
              >
              <button class="submit" @click="$emit('open')">
                <span>Join In</span>
              </button>
            </div>
          </div>
          <div class="forgot-password-contents" v-if="openPasswordReset">
            <div v-if="errors.reset_password_error">
              <p style="color: red; font-weight: bold">
                {{ errors.reset_password_error }}
              </p>
            </div>
            <div class="form-heading">
              <h1 class="heading">Forget Password</h1>
              <div class="close-login" @click="$emit('close')">
                <i class="far fa-times-circle"></i>
              </div>
            </div>
            <div class="form">
              <form>
                <label>Enter your email to reset password</label>
                <input
                  type="email"
                  placeholder="Email here"
                  v-model.trim="reset_email"
                  require
                />
                <span style="color: red" v-if="errors.reset_email">{{
                  errors.reset_email
                }}</span>
              </form>
            </div>
            <div class="submit-area">
              <button
                class="submit"
                @click="(openLogin = true), (openPasswordReset = false)"
              >
                <span>Back</span>
              </button>
              <button class="submit" @click="sendResetEmailForm">
                <span>Submit</span>
              </button>
            </div>
          </div>
          <div class="reset-code" v-if="openReset">
            <div v-if="errors.change_password_error">
              <p style="color: red; font-weight: bold">
                {{ errors.change_password_error }}
              </p>
            </div>
            <div class="form-heading">
              <h1 class="heading">Set A New Password</h1>
              <div class="close-login" @click="$emit('close')">
                <i class="far fa-times-circle"></i>
              </div>
            </div>

            <div class="form">
              <form>
                <p style="color: green">
                  We sent the reset password <strong>code</strong> to your
                  email, please check and use it.
                </p>
                <p>&nbsp;</p>
                <!-- <input type="text" placeholder="Code" require />  -->
                <div class="new-password">
                  <input
                    type="text"
                    name="reset_code"
                    id="rest-code"
                    v-model.trim="reset_code"
                    placeholder="Enter Code"
                    minlength="6"
                    maxlength="6"
                    autocomplete="off"
                  />
                  <label for="rest-code">Code</label>
                  <span style="color: red" v-if="errors.reset_code">{{
                    errors.reset_code
                  }}</span>
                </div>

                <div class="new-password">
                  <i
                    :class="'fas ' + password_eye + ' move_to_up'"
                    @click="showHidePassword"
                  ></i>
                  <input
                    :type="password_type"
                    name="reset_password"
                    id="new-password"
                    v-model.trim="reset_password"
                    placeholder="Enter New Password"
                  />
                  <label for="new-password">New Password</label>
                  <span style="color: red" v-if="errors.reset_password">{{
                    errors.reset_password
                  }}</span>
                </div>

                <div class="confirm-password">
                  <i
                    :class="'fas ' + conf_password_eye + ' move_to_up'"
                    @click="showHideConfPassword"
                  ></i>

                  <input
                    :type="conf_password_type"
                    name="reset_confirm_password"
                    id="confirm-password"
                    placeholder="Confirm Password"
                    v-model.trim="reset_cofirm_password"
                  />
                  <label for="confirm-password">Confirm Password</label>
                  <span
                    style="color: red"
                    v-if="errors.reset_cofirm_password"
                    >{{ errors.reset_cofirm_password }}</span
                  >
                </div>
              </form>
            </div>
            <div class="submit-area">
              <button
                class="submit"
                @click="(openReset = false), (openPasswordReset = true)"
              >
                <span>Back</span>
              </button>
              <button class="submit" @click="changePasswordNow">
                <span>Submit</span>
              </button>
            </div>
          </div>
          <!-- <div class="confirm-password-contents" v-if="openConfirm">
            <span style="color:red" v-if="errors.auth">{{errors.auth}}</span>
            <span></span>
            <div class="form-heading">
              <h1 class="heading">Set A New Password</h1>
              <div class="close-login" @click="$emit('close')">
                <i class="far fa-times-circle"></i>
              </div>
            </div>

            <div class="form">
              <form>
                <div class="new-password">
                  <input type="password" id="new-password" placeholder="Enter New Password" />
                  <label for="new-password">New Password</label>
                  <span
                    style="color:red"
                    v-if="errors.password_required"
                  >{{errors.password_required}}</span>
                </div>

                <div class="confirm-password">
                  <input type="password" id="confirm-password" placeholder="Confirm Password" />
                  <label for="confirm-password">Confirm Password</label>
                  <span
                    style="color:red"
                    v-if="errors.password_required"
                  >{{errors.password_required}}</span>
                </div>
              </form>
            </div>
            <div class="submit-area">
              <button class="submit">
                <span>Confirm</span>
              </button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <input type="hidden" v-model.trim="not_required_data" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
const axios = require("axios");

export default {
  name: "LoginForm",
  created() {
    var token = window.localStorage.getItem("token");
    if (token != undefined && token != "") {
      this.$router.replace("/");
    }
  },
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      showPasswordModal: false,
      openLogin: true,
      openReset: false,
      openPasswordReset: false,
      openConfirm: false,
      email: "",
      password: "",
      reset_email: "",
      reset_code: "",
      reset_password: "",
      reset_cofirm_password: "",
      not_required_data: "",
      emailRegx:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      passwordRegx:
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/,
      password_reset_success: false,
      password_eye: "fa-eye-slash",
      password_type: "password",
      conf_password_eye: "fa-eye-slash",
      conf_password_type: "password",
      errors: [],
    };
  },
  computed: {
    ...mapState(["success_message", "error_message", "response_message"]),
  },
  methods: {
    ...mapMutations(["setToken"]),

    processLogin() {
      this.password_reset_success = false;
      this.errors = [];
      if (this.email == "") {
        this.errors["email_required"] = "Email required.";
      } else if (this.password == "") {
        this.errors["password_required"] = "Password required.";
      } else {
        this.isLoading = true;
        this.errors = [];
        var formData = { email: this.email, password: this.password };
        axios
          .post("token/obtain", formData)
          .then((response) => {
            window.localStorage.setItem("token", response.data.token);
            this.setToken(response.data.token);
            axios.defaults.headers.common["authorization"] =
              "JWT " + response.data.token;
            this.isLoading = false;
            location.reload();
            // this.$router.replace("/");
          })
          .catch((error) => {
            if (error) {
              this.isLoading = false;
              this.errors["auth"] = "Invalid email or password.";
              this.not_required_data = "Invalid email or password.";
              // this.showAuthSnackbar = true
              // this.showSpinner = false
            }
            this.errored = true;
          });
      }
    },
    sendResetEmailForm() {
      this.password_reset_success = false;
      this.success_message = "";
      this.errors = [];
      if (this.reset_email == "") {
        this.errors["reset_email"] = "Email required.";
      } else if (!this.emailRegx.test(this.reset_email)) {
        this.errors["reset_email"] = "Wrong email format.";
      } else {
        this.isLoading = true;
        //this.errors = []
        let formData = new FormData();
        formData.append("email", this.reset_email);

        this.$store.dispatch("forgetPassword", formData).then(() => {
          if (this.success_message == "success") {
            this.isLoading = false;
            this.openReset = true;
            this.openPasswordReset = false;
          } else if (this.error_message == "errormessage") {
            this.isLoading = false;
            this.errors["reset_password_error"] = this.response_message;
            this.not_required_data = this.response_message;
          }
        });
      }
    },
    changePasswordNow() {
      this.password_reset_success = false;
      this.success_message = "";
      this.errors = [];
      if (this.reset_code == "") {
        this.errors["reset_code"] = "Code required.";
      } else if (this.reset_code.length != 6) {
        this.errors["reset_code"] = "Code length must have 6 characters.";
      } else if (this.reset_password == "") {
        this.errors["reset_password"] = "Password required.";
      } else if (!this.passwordRegx.test(this.reset_password)) {
        this.errors["reset_password"] =
          "Password must contain 6 characters with a capital & small letter, number & special character.";
      } else if (this.reset_cofirm_password == "") {
        this.errors["reset_cofirm_password"] = "Confirm Password required.";
      } else if (this.reset_cofirm_password != this.reset_password) {
        this.errors["reset_cofirm_password"] = "Confirm Password Not Matched.";
      } else {
        this.isLoading = true;
        //this.errors = []
        let formData = new FormData();
        formData.append("token", this.reset_code);
        formData.append("password", this.reset_password);
        formData.append("confirm_password", this.reset_cofirm_password);

        this.$store.dispatch("resetPassword", formData).then(() => {
          if (this.success_message == "success") {
            this.isLoading = false;
            this.password_reset_success = true;
            this.openLogin = true;
            this.openReset = false;
            this.not_required_data = "Reset password success.";
          } else if (this.error_message == "errormessage") {
            this.isLoading = false;
            this.errors["change_password_error"] = this.response_message;
            this.not_required_data = this.response_message;
          }
        });
      }
    },
    showHidePassword() {
      if (this.password_type == "password") {
        this.password_eye = "fa-eye";
        this.password_type = "text";
      } else {
        this.password_eye = "fa-eye-slash";
        this.password_type = "password";
      }
    },
    showHideConfPassword() {
      if (this.conf_password_type == "password") {
        this.conf_password_eye = "fa-eye";
        this.conf_password_type = "text";
      } else {
        this.conf_password_eye = "fa-eye-slash";
        this.conf_password_type = "password";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.move_to_up {
  z-index: 9999;
}
.md-field.md-theme-default:before {
  background: #4ba069 !important;
}
</style>
