<template>
  <div class="alert-modal">
    <div class="modal-box">
      <!-- <div class="close-modal" @click="$emit('close')">
        <i class="fas fa-times"></i>
      </div> -->
      <div class="modal-content">
        <div class="message-icon icon-success">
          <span>
            <i class="fas fa-check"></i>
          </span>
        </div>
        <div class="modal-message message-success">
          {{tag}}
        </div>
        <div class="continue-section">
          <router-link :to="{name: 'homepage'}" class="gg-button">
            Continue shopping
          </router-link>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalBillingSuccess',
  props:["tag"],
  mounted(){
    //
  }
};
</script>

<style lang="scss">
  .continue-section {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  .gg-button {
    padding: 5px 20px;
    border: 1px solid #4ba069;
    color: #fff;
    background: #4ba069;
    font-weight: bold;
    &:hover {
      background: #238545;
    }
  }
</style>
