<template>
  <footer>
    <div class="contact-section">
      <div class="help">
        <h1>Get to Know Us</h1>
        <a href="https://story.greengrowth.life" target="_blank">
          <span>About Us</span>
        </a>
      </div>
      <div class="connect-with-us">
        <h1>Connect with Us</h1>
        <div class="social-media-icon">
          <a href="https://twitter.com/greengrowth01" target="_blank">
            <span>
              <i class="fab fa-twitter"></i>
            </span>
          </a>
          <a
            href="https://www.facebook.com/greengrowthsince2016"
            target="_blank"
          >
            <span>
              <i class="fab fa-facebook-f"></i>
            </span>
          </a>
          <a
            href="https://www.instagram.com/greengrowthsince2016/"
            target="_blank"
          >
            <span>
              <i class="fab fa-instagram"></i>
            </span>
          </a>
        </div>
      </div>
      <div class="contact-us">
        <h1>Contact Us</h1>
        <a href="tel:+97714112128" target="_blank"
          ><span>977-1-4112128</span></a
        >
      </div>
      <div class="help">
        <h1>Our Terms & Policy</h1>
        <router-link
          :to="{
            name: 'staticPage',
            params: { slug: 'terms-and-conditions' },
          }"
        >
          <span>Terms And Conditions</span>
        </router-link>

        <router-link
          :to="{ name: 'staticPage', params: { slug: 'privacy-policy' } }"
        >
          <span>Privacy Policy</span>
        </router-link>
      </div>
    </div>
    <div class="copyright">
      <div class="copyright-icon">
        <!-- <span class="material-icons"> copyright </span> -->&copy;
        Copyright<span>Green Growth</span>
      </div>
      <span>
        Powered by
        <a href="https://abhiyantrik.com/" target="_blank">
          <span> Abhiyantrik Technology</span></a
        >
      </span>
    </div>
  </footer>
</template>

<script>
export default {};
</script>
<style></style>
